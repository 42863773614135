import {Injectable} from '@angular/core';
import {ContentItem} from '../../shared/classes/services/content_item';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {ApiPeopleService} from './api-people.service';
import {first, take} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ApiJobsService} from '../../jobs/services/api-jobs.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import * as _ from 'lodash';
import {StateService} from '../../core/services/state/state.service';
// import {TestAndReportHandlingService} from './test-and-report-handling.service';
// import {request} from 'webdriver-manager/built/lib/utils';

@Injectable({
    providedIn: 'root'
})
export class PersonService extends ContentItem {

    public loading = false;
    private person;
    private job;

    readonly persistentVariables = [
        'person', 'job'
    ];

    // servicePersonChanged = new BehaviorSubject(null);
    // servicePersonChanged$ = this.servicePersonChanged.asObservable();

    constructor(
        private translateService: TranslateService,
        private apiPeopleService: ApiPeopleService,
        private apiJobsService: ApiJobsService,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService
    ) {
        super(router, deviceService, stateService);
        this.restoreServiceData('personService', this.persistentVariables, this);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Methods /////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Requests manipulations
    checkRequestsContainsMpoTraits() {
        return (
            this.checkRequestsContains('isSurvey', 'mpo') &&
            (
                this.checkRequestsContains('variant', '1,0') ||
                this.checkRequestsContains('variant', '1,1')
            )
        );
    }

    checkRequestsContainsMpoPrb() {
        return (
            this.checkRequestsContains('isSurvey', 'mpo') &&
            (
                this.checkRequestsContains('variant', '1,1') ||
                this.checkRequestsContains('variant', '0,1')
            )
        );
    }

    checkRequestsContains(field, value, globalPcr = false) {
        let contains = false;
        if (
            this.hasRequest
        ) {
            let requests = _.get(this, 'person.evaluations.requests');
            // tslint:disable-next-line:no-shadowed-variable
            for (let request of requests) {
                if (!globalPcr) { // hasSurveyRa, or variant
                    // has a given type in the type array
                    if (field === 'typeContains') {
                        contains = request['type'].includes(value);
                        // is an mpo test
                    } else if (field === 'typeContainsOnly') {
                        contains = (request['type'].length === 1 && request['type'][0] === value);
                    } else if (field === 'isSurvey') {
                        if (value === 'mpo') {
                            contains = (
                                request['type'].includes('mpo') ||
                                request['type'].includes('dit') ||
                                request['type'].includes('talents') ||
                                request['type'].includes('satellite')
                            );
                        } else if (value === 'iac' || value === 'ra') {
                            contains = request['type'].includes('ra');
                        }
                        // checks specific values in the request
                    } else {
                        contains = (request[field] === value);
                    }

                } else if (globalPcr) {
                    contains = (
                        (request.variant === '1,1' || request.variant === '0,1') &&
                        (request.variant !== '0,0')
                    );
                }
            }
        }
        return contains;
    }

    /**
     * renamed from checkQuestionnaireType(questionnaire, value)
     * replaces evaluationsRequestsContains(type)
     * and evaluationRequestsContainingOnly(type)
     * @param type
     * @param variants
     */
    hasARequestSent(type, variants = null) {
        switch (type) {
            case 'iac':
                type = 'ra';
                break;
            case 'prb':
                type = 'mpo';
                variants = ['1,0', '1,1'];
                break;
        }
        if (typeof variants === 'string' || variants instanceof String) {
            variants = [variants];
        }
        for (let i in this.requests) {
            if (this.requests.hasOwnProperty(i)) {
                if (this.requests[i].type.includes(type)) {
                    if (variants) {
                        for (let j in variants) {
                            if (variants.hasOwnProperty(j) && this.requests[i].variant === variants[j]) {
                                return true;
                            }
                        }
                    } else {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    evaluationRequestsContainingOnly(type) {
        let requestsContainingOnly = [];
        let variant = [];

        if (type === 'iac') {
            type = 'ra';
            variant = ['0,0'];
        }
        if (type === 'prb') {
            variant = ['0,1'];
        } else if (type === 'ra') {
            variant = ['0,0'];
        } else if (type === 'mpo' || type === 'personality') {
            variant = ['1,1', '1,0'];
        }
        if (variant.length === 0) {
            throw 'invalid type given at people-sidebar.component.ts :: evaluationRequestsContainingOnly()';
        }

        for (let i in this.requests) {
            if (this.requests.hasOwnProperty(i)) {
                if (variant.includes(this.requests[i].variant) || (type === 'ra' && this.requests[i].hasSurveyRa)) {
                    requestsContainingOnly.push(this.requests[i]);
                }
            }
        }
        return requestsContainingOnly;
    }

    fetchPersonsJob(jobId, callBack = null, specificState = null, action = null, obsResult = null,
                    person = null, reportType = null, id = null) {
        this.job = null;
        if (jobId && jobId.trim() !== '') {
            let jobObservable = this.apiJobsService.job([jobId]).pipe(take(1)).subscribe(
                (res) => {
                    this.job = res;
                    this.stateService['people'].stateChanged.next({jobId: jobId});

                    if (callBack === 'setPersonToDisplayWithId') {
                        if (!action) {
                            action = 'forceSidebarToReport';
                        }
                        this.setPersonToDisplayWithIdCB(specificState, action, obsResult, person, reportType, id);
                    }

                    if (callBack === 'setPersonToDisplayWithId') {
                        this.personToDisplayCB(obsResult, person);
                    }

                    jobObservable.unsubscribe();
                },
                (error: any) => {
                    if (error.status === 400) {
                        // jobNotFound
                        if (callBack === 'setPersonToDisplayWithId') {
                            if (!action) {
                                action = 'forceSidebarToReport';
                            }
                            this.setPersonToDisplayWithIdCB(specificState, action, obsResult, person, reportType, id);
                            this.personToDisplayCB(obsResult, person);
                        }

                        jobObservable.unsubscribe();
                    }
                }
            );
        }
    }

    setPersonToDisplayWithIdCB(specificState = 'people', action, obsResult, person, reportType= 'personality', id) {
        // 'personService'
        this.storeServiceData('personService', this.persistentVariables, this);
        if (specificState === null) {
            specificState = 'people';
        }
        this.stateService[specificState].personToDisplayId = person.id;

        if (action === 'forceSidebarToReport' || (action === 'resetSidebarToReport' && this.isRightModule(specificState))) {
            obsResult = this.resetSidebarToReport(specificState);
        } else if (action === 'deleteRequest') {
            obsResult['deleteRequest'] = true;
        } else if (action === 'mobileView') {
            this.router.navigate(['m/people/view/' + id + '/' + reportType]).then(() => {});
        } else if (action === 'resetSidebarToFilters') {
            this.stateService[specificState].sideBarStatus = 'searchFilters';
        } else {
            this.stateService[specificState].sideBarStatus = 'recentActivities';
        }
        obsResult['personId'] = person.id;
        this.stateService[specificState].stateChanged.next(obsResult);
    }

    setPersonToDisplayWithId(id, action = null, specificState = 'people', reportType = 'personality') {
        let obsResult = {};
        this.loading = true;
        this.job = undefined;
        let recordObservable = this.apiPeopleService.record([id]).pipe(first()).subscribe(
            (person) => {
                this.person = person;
                this.loading = false;
                let navigate = (action === 'navigate');
                if (!action || action === 'navigate') {
                    action = 'forceSidebarToReport';
                }
                if (this.jobId) {
                    this.fetchPersonsJob(this.jobId, 'setPersonToDisplayWithId', specificState, action,
                        obsResult, person, reportType, id);
                } else {
                    this.setPersonToDisplayWithIdCB(specificState, action, obsResult, person, reportType, id);
                }

                if (navigate) {
                    this.router.navigate(['people/report/' + id + '/' + reportType]).then();
                }
                recordObservable.unsubscribe();
            }
        );
    }

    resetPersonToDisplay() {
        this.personToDisplay = {};
        this.job = null;
        this.stateService.people.personToDisplayId = undefined;
        this.stateService.people.stateChanged.next({
            'personDeleted': true
        });
        if (this.isMobile) {
            this.router.navigate(['m/people/list']).then(() => {});
        } else {
            this.stateService.people.sideBarStatus = 'recentActivities';
        }
    }

    isRightModule(specificState) {
        if (specificState === 'people' && !this.isArchived) {
            return true;
        }
        return !!(specificState === 'archivesPeople' && this.isArchived);

    }

    resetSidebarToReport(specificState) {
        let obsResult = {};
        if (this.stateService[specificState].sideBarStatus !== 'report') {
            this.stateService[specificState].sideBarStatus = 'reports';
            obsResult['sideBarStatus'] = 'reports';
        }
        if (this.stateService[specificState].prbIndex !== 0) {
            this.stateService[specificState].prbIndex = 0;
            obsResult['prbIndex'] = 0;
        }
        /** TODO : Probably not needed - refactoring
         if (this.stateService[specificState].selectedInfoQuestionTab !== 0) {
            this.stateService[specificState].selectedInfoQuestionTab = 0;
            obsResult['selectedInfoQuestionTab'] = 0;
        }*/
        return (Object.keys(obsResult).length === 0) ? null : obsResult;
    }

    reportOfType(type) {
        if (this.evaluations && this.evaluations.hasOwnProperty(type)) {
            return this.evaluations[type];
        }
        return undefined;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Mutators ////////////////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // GENERIC MUTATORS

    personToDisplayCB(obsResult, person) {
        // 'personService'
        this.storeServiceData('personService', this.persistentVariables, this);
        this.stateService[this.actualModule.name].personToDisplayId = person.id;

        obsResult['personId'] = person.id;
        this.stateService[this.actualModule.name].stateChanged.next(obsResult);
    }

    set personToDisplay(person) {
        if (this.person.id !== person.id || person.updateData) {
            let obsResult = {};
            this.person = person;

            this.stateService.people.personToDisplayId = person.id;

            if (this.jobId) {
                this.job = undefined;
                this.fetchPersonsJob(this.jobId, 'personToDisplay', null, null,
                    obsResult, person);
            } else {
                this.personToDisplayCB(obsResult, person);
            }

            // 'personService'
            this.storeServiceData('personService', this.persistentVariables, this);
            this.stateService[this.actualModule.name].personToDisplayId = person.id;

            obsResult['personId'] = person.id;
            this.stateService[this.actualModule.name].stateChanged.next(obsResult);
        }
    }

    set jobId(id) {
        this.person.jobId = id;
    }

    get personToDisplay(): any {
        return _.get(this, 'person');
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // OTHER MUTATORS

    // Metadata

    get id(): string {
        return _.get(this, 'person.id');
    }

    get date(): string {
        return _.get(this, 'person.date');
    }

    get creationDate(): string {
        return _.get(this, 'person.creationDate');
    }

    get creationDateForDisplay(): string {
        return this.convertDate(_.get(this, 'person.creationDate'), this.translateService.currentLang);
    }

    get firstName(): string {
        return _.get(this, 'person.firstName');
    }

    get lastName(): string {
        return _.get(this, 'person.lastName');
    }

    get fullName(): string {
        let name = '';
        name += (this.firstName) ? this.firstName + ' ' : name;
        name += (this.lastName) ? this.lastName : name;
        return name;
    }

    get subAccount(): number {
        return _.get(this, 'person.subAccount');
    }

    get email(): string {
        return _.get(this, 'person.email');
    }

    get recordType(): string {
        return _.get(this, 'person.recordType');
    }

    get organisation(): string {
        return _.get(this, 'person.organisation');
    }

    get division(): string {
        return _.get(this, 'person.division');
    }

    get departmentToDisplay(): string {
        let departmentName;
        if (this.department !== undefined) {
            if (Array.isArray(this.department)) {
                if (this.department[0] && this.department[0][this.translateService.currentLang]) {
                    departmentName = this.department[0][this.translateService.currentLang];
                } else {
                    if (_.get(this.stateService, 'session.sessionData.structure.departments')) {
                        let depCtx = this.stateService.session.sessionData.structure.departments.filter(dep => dep.id === this.department[0]);
                        if (depCtx && depCtx[0]) {
                            departmentName = depCtx[0][this.translateService.currentLang];
                        }
                    }
                }
            } else {
                departmentName = this.department[this.translateService.currentLang];
            }
        }
        return departmentName;
    }

    get department(): any {
        return _.get(this, 'person.department');
    }

    get jobTitle(): string {
        return _.get(this, 'person.jobTitle');
    }

    get employeePosition(): string {
        let jobTitle = null;
        if (this.job && this.jobTitle) {
            jobTitle = this.job.jobTitle;
        }
        if (!jobTitle && this.jobTitle) {
            jobTitle = this.jobTitle;
        }
        return jobTitle;
    }

    get jobType(): string {
        return _.get(this, 'person.jobType');
    }

    get jobId(): string {
        return _.get(this, 'person.jobId');
    }

    get isArchived(): boolean {
        return _.get(this, 'person.archive');
    }

    set evaluations(evaluations) {
        this.person.evaluations = evaluations;
    }

    get evaluations(): any {
        return _.get(this.person, 'evaluations');
    }

    get hasResearchQuestions(): boolean {
        return _.get(this, 'person.evaluations.researchQuestions');
    }

    get testStatuses() {
        return _.get(this, 'personToDisplay.testStatuses');
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Job

    get jobsId(): string {
        return _.get(this, 'job.id');
    }

    get jobsJobTitle(): string {
        if (this.jobId) {
            return _.get(this, 'job.jobTitle');
        }
        return;
    }

    get hasReport(): boolean {
        return (this.hasPersonality || this.hasPersonalityOnHold || this.hasIac || this.hasIacOnHold);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // MPO PERSONALITY

    get hasPersonality(): boolean {
        return (_.get(this, 'person.evaluations.mpo.info.status') === true);
    }

    get hasPersonalityOnHold(): boolean {
        return (_.get(this, 'person.evaluations.mpo.info.status') === false);
    }

    get personalityReport(): any {
        if (this.hasPersonality) {
            return _.get(this, 'person.evaluations.mpo');
        }
        return undefined;
    }

    get personalityReportInfo(): any {
        if (this.hasPersonality) {
            return _.get(this.personalityReport, 'info');
        }
        return undefined;
    }

    get personalityScores() {
        return _.get(this.personalityReport, 'scores');
    }

    get prbScores(): any {
        if (this.hasPrb) {
            let scores = 'list[' + this.prbIndex + '].scores';
            return _.get(this.prbReport, scores);
        }
    }

    get portrait(): string {
        return _.get(this, 'personalityReport.info.type');
    }

    get personalityRequestedByInfo(): any {
        return _.get(this, 'personalityReport.info.requestedBy');
    }

    get personalityTimeSpent(): any {
        let timeSpent: any;
        // PRB
        if (this.prbReportInfo && this.prbReportInfo.finalTimeSpent) {
            timeSpent = {
                size: this.prbReportInfo.finalTimeSpent.length,
                data: this.prbReportInfo.finalTimeSpent
            };
        } else if (this.personalityReportInfo && this.personalityReportInfo.finalTimeSpent) {
            timeSpent = {
                size: this.personalityReportInfo.finalTimeSpent.length,
                data: this.personalityReportInfo.finalTimeSpent
            };
        }
        return timeSpent;
    }

    get personalityNorm() {
        return _.get(this.personalityReportInfo, 'norm');
    }

    get personalityMethod() {
        return _.get(this.personalityReportInfo, 'method');
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // MPO COMMUNICATION

    get hasCommunication(): boolean {
        return (_.get(this, 'person.evaluations.dit.info.status') === true);
    }

    get hasCommunicationOnHold(): boolean {
        return (_.get(this, 'person.evaluations.dit.info.status') === false);
    }

    get communicationReport(): any {
        if (this.hasCommunication) {
            return _.get(this, 'person.evaluations.dit');
        }
        return undefined;
    }

    get communicationIsInvalid(): boolean {
        if (_.get(this, 'communicationReport.info.type') !== undefined) {
            return (this.communicationReport.info.type === 'INV');
        }
    }

    get communicationReportInfo() {
        return (this.communicationReport) ? this.communicationReport.info : null;
    }

    get communicationScore() {
        return (this.communicationReportInfo) ? this.communicationReport.info.quad : null;
    }

    get communicationAd() {
        return (this.communicationReportInfo) ? this.communicationReport.info.adaptability : null;
    }

    get communicationInfoRequestedBy(): any {
        return _.get(this, 'communicationReport.info.requestedBy');
    }

    get communicationRequestedBy() {
        return _.get(this.person.communicationReportInfo, 'requestedBy');
    }

    get communicationNorm() {
        return _.get(this.communicationReportInfo, 'norm');
    }

    get communicationMethod() {
        return _.get(this.communicationReportInfo, 'method');
    }

    get communicationTimeSpent(): any {
        let timeSpent: any;
        // PRB
        if (this.prbReportInfo && this.prbReportInfo.finalTimeSpent) {
            timeSpent = {
                size: this.prbReportInfo.finalTimeSpent.length,
                data: this.prbReportInfo.finalTimeSpent
            };
        } else if (this.person.communicationReportInfo && this.person.communicationReportInfo.finalTimeSpent) {
            timeSpent = {
                size: this.person.communicationReportInfo.finalTimeSpent.length,
                data: this.person.communicationReportInfo.finalTimeSpent
            };
        }
        return timeSpent;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // MPO SATELLITE

    get hasSatellite(): boolean {
        return (_.get(this, 'person.evaluations.satellite.info.status') === true);
    }

    get hasSatelliteOnHold(): boolean {
        return (_.get(this, 'person.evaluations.satellite.info.status') === false);
    }

    get satelliteReport(): any {
        let satelliteReport = undefined;
        if (this.hasSatellite) {
            satelliteReport = _.get(this.evaluations, 'satellite');
        }
        return satelliteReport;
    }

    get satelliteInfo() {
        return _.get(this.satelliteReport, 'info');
    }

    get satelliteJob() {
        return _.get(this.satelliteInfo, 'job');
    }

    get satelliteJobId() {
        // console.log(this.satelliteJob, _.get(this.satelliteJob, 'job'));
        return this.satelliteJob; // _.get(this.satelliteJob, 'job');
    }

    get satelliteScore() {
        if (this.satelliteJob) { // if (this.satelliteJob && this.satelliteJob.id) {
            return _.get(this.satelliteInfo, 'satelliteScore');
        }
        return;
    }

    get hasSatelliteJobProfile(): any {
        return (this.satelliteScore && this.satelliteScore !== 'noJobProfile');
    }

    get satelliteRequestedByInfo(): any {
        return _.get(this.satelliteInfo, 'requestedBy');
    }

    get satelliteNorm() {
        return _.get(this.satelliteInfo, 'norm');
    }

    get satelliteMethod() {
        return _.get(this.satelliteInfo, 'method');
    }

    get satelliteFinalTimeSpent() {
        return _.get(this.satelliteInfo, 'finalTimeSpent');
    }

    get satelliteTimeSpent(): any {
        let timeSpent: any;
        if (this.prbReportInfo && this.prbReportInfo.finalTimeSpent) {
            timeSpent = {
                size: this.prbReportInfo.finalTimeSpent.length,
                data: this.prbReportInfo.finalTimeSpent
            };
        } else if (this.satelliteFinalTimeSpent) {
            timeSpent = {
                size: this.satelliteFinalTimeSpent.length,
                data: this.satelliteFinalTimeSpent
            };
        }
        return timeSpent;
    }

    satelliteJobChange(id) {
        let profilerId = (id !== null && id.target.value !== 'none' && id.target.value) ? id.target.value : '';
        let satelliteObservable = this.apiPeopleService.editSatellite(
            [this.person.id],
            {
                satelliteJobId: profilerId
            }
        ).pipe(take(1)).subscribe(
            res => {
                // this.person = res;
                this.setPersonToDisplayWithId(res.id);
                this.storeServiceData('personService', this.persistentVariables, this);
                this.stateService.people.stateChanged.next({'profilerId': id.target.value});
                satelliteObservable.unsubscribe();
            });
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // MPO TALENTS

    get hasTalentsOnHold(): boolean {
        return (_.get(this, 'person.evaluations.talents.info.status') === false);
    }

    get hasTalents(): boolean {
        return (_.get(this.talentsReportInfo, 'status') === true);
    }

    get talentsReport(): any {
        return _.get(this.evaluations, 'talents');
    }

    get talentsReportInfo(): any {
        return (_.get(this.talentsReport, 'info'));
    }

    get talentsScore() {
        return _.get(this.talentsReport, 'info.talentsScore');
    }

    get talentsRequestedByInfo(): any {
        return _.get(this, 'talentsReport.info.requestedBy');
    }

    get talentsInfo() {
        return _.get(this.talentsReport, 'info');
    }

    get talentsNorm() {
        return _.get(this.talentsInfo, 'norm');
    }

    get talentsMethod() {
        return _.get(this.talentsInfo, 'method');
    }

    get talentsFinalTimeSpent() {
        return _.get(this.talentsInfo, 'finalTimeSpent');
    }

    get talentsTimeSpent(): any {
        let timeSpent: any;
        if (this.prbReportInfo && this.prbReportInfo.finalTimeSpent) {
            timeSpent = {
                size: this.prbReportInfo.finalTimeSpent.length,
                data: this.prbReportInfo.finalTimeSpent
            };
        } else if (this.talentsFinalTimeSpent) {
            timeSpent = {
                size: this.talentsFinalTimeSpent.length,
                data: this.talentsFinalTimeSpent
            };
        }
        return timeSpent;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // PRB

    get hasPrb(): boolean {
        if (_.get(this, 'person.evaluations.prb.list') !== undefined) {
            for (let i = 0; i < this.person.evaluations.prb.list.length; i++) {
                let prbItem = this.evaluations.prb.list[i];
                if (prbItem.info.status) {
                    return true;
                }
            }
        }
        return false;
    }

    get canTakeExtraPrb(): boolean {
        return !!(
            this.hasAnMpoReport && !(
                this.checkRequestsContains('isSurvey', 'mpo') &&
                this.checkRequestsContains('variant', '0,1')
            )
        );
    }

    get hasPrbOnHold(): boolean {
        if (_.get(this, 'person.evaluations.prb.list') !== undefined) {
            for (let i = 0; i < this.person.evaluations.prb.list.length; i++) {
                if (
                    this.person &&
                    this.person.evaluations &&
                    this.person.evaluations.prb &&
                    this.person.evaluations.prb.list &&
                    this.person.evaluations.prb.list[i] &&
                    !this.person.evaluations.prb.list[i].status &&
                    !this.person.evaluations.prb.list[i].info.status
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    hasEvaluationsType(type, onHold = false) {
        switch (type) {
            case 'personality':
                return (onHold) ? this.hasPersonalityOnHold : this.hasPersonality;
            case 'communication':
                return (onHold) ? this.hasCommunicationOnHold : this.hasCommunication;
            case 'talents':
                return (onHold) ? this.hasTalentsOnHold : this.hasTalents;
            case 'satellite':
                return (onHold) ? this.hasSatelliteOnHold : this.hasSatellite;
            case 'iac':
                return (onHold) ? this.hasIacOnHold : this.hasIac;
            default:
                return false;
        }
    }

    get prbReport(): any {
        if (this.hasPrb) {
            return _.get(this, 'evaluations.prb');
        }
    }

    get prbIndex(): number {
        return this.stateService.people.prbIndex;
    }

    get prbReportInfo(): any {
        if (this.hasPrb) {
            let info = 'list[' + this.prbIndex + '].info';
            return _.get(this.prbReport, info);
        }
    }

    get lastPrbReport(): any {
        let list = _.get(this, 'prbReport.list');
        if (list !== undefined) {
            return list[0];
        }
        return false;
    }

    set prbReport(report) {
        this.person.evaluations.prb = report;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // IAC

    get hasIac(): boolean {
        return (_.get(this, 'person.evaluations.iac.info.status') === true);
    }

    get canTakeIac() {
        return !(this.hasAnIacReport || this.hasIacRequest);
    }

    get hasIacOnHold(): boolean {
        return (_.get(this, 'person.evaluations.iac.info.status') === false);
    }

    get iacReport(): any {
        if (this.hasIac) {
            return _.get(this, 'person.evaluations.iac');
        }
        return undefined;
    }

    get iacScores(): any {
        return _.get(this, 'iacReport.scores');
    }

    get iacInfo(): any {
        return _.get(this, 'iacReport.info');
    }

    get iacInfoBruteScore(): any {
        return _.get(this.iacInfo, 'bruteScore');
    }

    get iacInfoAnsweredQuestions(): any {
        return _.get(this.iacInfo, 'answeredQuestions');
    }

    get iacTime(): any { // ToDo what is the correct type here??
        return _.get(this, 'iacInfo.passationTime');
    }

    get iacTimeForGraph(): string {
        if (this.iacTime === undefined) {
            return;
        }
        const timeInSec = this.iacTime;
        let minutes: any = Math.floor(timeInSec / 60);
        let seconds: any = (timeInSec - (minutes * 60));
        if (seconds === 60) {
            minutes++;
            seconds = 0;
        }
        minutes = (minutes < 10) ? '0' + minutes : '' + minutes;
        seconds = (seconds < 10) ? '0' + seconds : '' + seconds;
        return minutes + ':' + seconds;
    }

    get iacTimeToString(): string {
        if (this.iacTime === undefined) {
            return;
        }
        const timeInSec = this.iacTime;
        let minutes = Math.floor(timeInSec / 60);
        let seconds = (timeInSec - (minutes * 60));
        if (seconds === 60) {
            minutes++;
            seconds = 0;
        }
        return minutes + ' min. ' + seconds + ' sec.';
    }

    get iacNormalizedScore() {
        return _.get(this.iacScores, 'normalized');
    }

    get iacNorm() {
        return _.get(this.iacInfo, 'norm');
    }

    get iacNormLanguage() {
        let lg;
        if (this.iacNorm) {
            let iacNorm = this.iacNorm.split(' ');
            lg = iacNorm[1];
        }
        return lg;
    }

    get iacMethod() {
        return _.get(this.person.iacInfo, 'method');
    }

    get iacPassationTime() {
        if (isNaN(this.iacInfo.passationTime) || !this.iacReport) {
            return;
        } else {
            const hours = Math.floor((this.iacInfo.passationTime / 3600) % 60);
            const minutes = Math.floor((this.iacInfo.passationTime / 60) % 60);
            const seconds = this.iacInfo.passationTime % 60;
            return ((hours < 10) ? '0' + hours.toString(10) : hours.toString(10)) + ':' +
                ((minutes < 10) ? '0' + minutes.toString(10) : minutes.toString(10)) + ':' +
                ((seconds < 10) ? '0' + seconds.toString(10) : seconds.toString(10));
        }
    }

    /**
     * alias
     */
    get hasAnIacReport(): boolean {
        return this.hasIac;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Requests

    get hasRequest(): boolean {
        let requests = _.get(this, 'person.evaluations.requests');
        return (requests !== undefined && requests.length > 0);
    }

    get requests(): any {
        return _.get(this, 'person.evaluations.requests');
    }

    get hasMpoRequest(): boolean {
        let requests = this.requests;
        if (requests !== undefined && requests.length > 0) {
            for (let i = 0; i < requests.length; i++) {
                if (
                    requests[i].type.includes('mpo') ||
                    requests[i].type.includes('dit') ||
                    requests[i].type.includes('talents') ||
                    requests[i].type.includes('satellite')
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    get hasPrbRequest(): boolean {
        let requests = this.requests;
        if (requests !== undefined && requests.length > 0) {
            for (let i = 0; i < requests.length; i++) {
                if (
                    requests[i].type.includes('mpo') &&
                    requests[i].variant === '0,1'
                ) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Returns the MPO request if it exists.
     * Note: it is assumed that only one of these
     *       can exists at the same time.
     */
    get mpoRequest(): any {
        let requests = this.requests;
        if (requests !== undefined && requests.length > 0) {
            for (let i = 0; i < requests.length; i++) {
                if (
                    requests[i].type.includes('mpo') ||
                    requests[i].type.includes('dit') ||
                    requests[i].type.includes('talents') ||
                    requests[i].type.includes('satellite')
                ) {
                    return requests[i];
                }
            }
        }
        return undefined;
    }

    get hasIacRequest(): boolean {
        let requests = _.get(this, 'person.evaluations.requests');
        if (requests !== undefined && requests.length > 0) {
            for (let i = 0; i < requests.length; i++) {
                if (requests[i].type.includes('ra')) {
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Returns the IAC request if it exists.
     * Note: it is assumed that only one of these
     *       can exists at the same time.
     */

    get iacRequest(): any {
        let requests = this.requests;
        if (requests !== undefined && requests.length > 0) {
            for (let i = 0; i < requests.length; i++) {
                if (requests[i].type.includes('ra')) {
                    return requests[i];
                }
            }
        }
        return undefined;
    }

    get requestedByInfo(): any {
        return _.get(this.person, 'requestedByInfo');
    }

    get mpoRequestRequestedBy(): string {
        return (_.get(this.mpoRequest, 'requestedByName') && _.get(this.mpoRequest, 'requestedByName') !== '') ?
            _.get(this.mpoRequest, 'requestedByName') :
            _.get(this.mpoRequest, 'requestedBy');
    }

    get iacRequestRequestedBy(): string {
        return (_.get(this.iacRequest, 'requestedByName') && _.get(this.iacRequest, 'requestedByName') !== '') ?
            _.get(this.iacRequest, 'requestedByName') :
            _.get(this.iacRequest, 'requestedBy');
    }

    get iacReportRequestedBy() {
        return (_.get(this.iacInfo, 'requestedByName') && _.get(this.iacInfo, 'requestedByName') !== '') ?
            _.get(this.iacInfo, 'requestedByName') :
            _.get(this.iacInfo, 'requestedBy.fullName');
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // questionnaire's info

    get questionnaireData(): string {
        return _.get(this, 'person.evaluations.questionnaireData');
    }

    // Tests for display

    /**
     * replaces borderlineCase()
     */
    get isABorderlineCase() {
        return (
            this.personalityScores &&
            (
                (
                    this.personalityScores['A'] === 0 &&
                    this.personalityScores['E'] === 0 &&
                    this.personalityScores['P'] === 0 &&
                    this.personalityScores['S'] === 0
                ) || (
                    this.personalityScores['A'] === 10 &&
                    this.personalityScores['E'] === 10 &&
                    this.personalityScores['P'] === 10 &&
                    this.personalityScores['S'] === 10
                ) || (
                    this.communicationIsInvalid
                )
            )
        );
    }

    /**
     * replaces hasMpoReport()
     */

    get hasAnMpoReport() {
        return (
            this.hasPersonality ||
            this.hasCommunication ||
            this.hasSatellite ||
            this.hasTalents
        );
    }

    get hasMpoOnHold() {
        return (
            this.hasPersonalityOnHold ||
            this.hasCommunicationOnHold ||
            this.hasSatelliteOnHold ||
            this.hasTalentsOnHold ||
            this.hasIacOnHold
        );
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // date getters

    /**
     * This is firstDate() renamed
     * replaces completionDate() also
     *
     * Check if this method is still pertinent with
     * firstEvaluationDate produced by the back-end
     */
    get mpoCompletionDate() {
        let mpoCompletionDate = _.get(this, 'person.firstEvaluationDate') ?
            _.get(this, 'person.firstEvaluationDate') :
            undefined;
        if (mpoCompletionDate === undefined) {
            let dates = {
                personality: _.get(this, 'personalityReport.info.date'),
                communication: _.get(this, 'communicationReport.info.date'),
                talents: _.get(this, 'talentsReport.info.date'),
                satellite: _.get(this, 'satelliteReport.info.date')
            };
            for (let report in dates) {
                if (
                    dates[report] !== undefined && (
                        moment(dates[report]).isBefore(mpoCompletionDate) ||
                        mpoCompletionDate === undefined
                    )
                ) {
                    mpoCompletionDate = dates[report];
                }
            }

        }
        return mpoCompletionDate;
    }

    get mpoCompletionDateToDisplay() {
        return this.convertDate(this.mpoCompletionDate);
    }

    get personalityDate() {
        let personalityDate;
        if (this.hasPersonality || this.hasPersonalityOnHold) {
            personalityDate = _.get(this, 'personalityReport.info.date');
        } else {
            personalityDate = (this.hasMpoRequest && this.mpoRequest.type.includes('mpo')) ?
                _.get(this, 'mpoRequest.date') :
                undefined;
        }
        // check if the personality date is an object that contains a sec property
        if (personalityDate && personalityDate.sec) {
            personalityDate = personalityDate.sec * 1000;
        }
        return personalityDate;
    }

    get personalityDateToDisplay() {
        return this.convertDate(this.personalityDate, this.translateService.currentLang);
    }

    get communicationDate() {
        let communicationDate;
        if (this.hasCommunication || this.hasCommunicationOnHold) {
            communicationDate = _.get(this, 'communicationReport.info.date');
        } else {
            communicationDate = (this.hasMpoRequest && this.mpoRequest.type.includes('dit')) ?
                _.get(this, 'mpoRequest.date') :
                undefined;
        }
        return communicationDate;
    }

    get communicationDateToDisplay() {
        return this.convertDate(this.communicationDate, this.translateService.currentLang);
    }

    get talentsDate() {
        let talentsDate;
        if (this.hasTalents || this.hasTalentsOnHold) {
            talentsDate = _.get(this, 'talentsReport.info.date');
        } else {
            talentsDate = (this.hasMpoRequest && this.mpoRequest.type.contains('talents')) ?
                _.get(this, 'mpoRequest.date') :
                undefined;
        }
        return talentsDate;
    }

    get talentsDateToDisplay() {
        return this.convertDate(this.talentsDate, this.translateService.currentLang);
    }

    get satelliteDate() {
        let satelliteDate;
        if (this.hasSatellite || this.hasSatelliteOnHold) {
            satelliteDate = _.get(this, 'satelliteReport.info.date');
        } else {
            satelliteDate = (this.hasMpoRequest && this.mpoRequest.type.includes('satellite')) ?
                _.get(this, 'mpoRequest.date') :
                undefined;
        }
        return satelliteDate;
    }

    get satelliteDateToDisplay() {
        return this.convertDate(this.satelliteDate, this.translateService.currentLang);
    }

    /**
     * Changed name prbCompletionDate to lastPrbCompletionDate to remove ambiguity
     */

    get lastPrbCompletionDate() {
        let lastPrbCompletionDate;
        let prbList = _.get(this, 'prbReport.list');
        if (prbList !== undefined) {
            prbList.forEach(r => {
                if (r.info && r.info.date) {
                    if (!lastPrbCompletionDate || r.info.date > lastPrbCompletionDate) {
                        lastPrbCompletionDate = r.info.date;
                    }
                }
            });
        }
        return lastPrbCompletionDate;
    }

    get lastPrbCompletionDateToDisplay() {
        return this.convertDate(this.lastPrbCompletionDate, this.translateService.currentLang);
    }

    /**
     * Changed name prbCompletionDate to lastPrbCompletionDate to remove ambiguity
     */

    get firstPrbCompletionDate() {
        let firstPrbCompletionDate;
        let prbList = _.get(this, 'prbReport.list');
        if (prbList !== undefined) {
            prbList.forEach(r => {
                if (r.info && r.info.date) {
                    if (!firstPrbCompletionDate || r.info.date < firstPrbCompletionDate) {
                        firstPrbCompletionDate = r.info.date;
                    }
                }
            });
        }
        return firstPrbCompletionDate;
    }

    get firstPrbCompletionDateToDisplay() {
        return this.convertDate(this.firstPrbCompletionDate, this.translateService.currentLang);
    }

    get iacCompletionDate() {
        let iacDate;
        if (this.hasIac || this.hasIacOnHold) {
            iacDate = _.get(this, 'iacReport.info.date');
        } else {
            iacDate = (this.hasIacRequest && this.iacRequest.type.includes('ra')) ?
                _.get(this, 'iacRequest.date') :
                undefined;
        }
        return iacDate;
    }

    get iacCompletionDateToDisplay() {
        let iacCompletionDate;
        // if iacCompletionDate is an object that contains a sec property it's a request date
        // that means the CAI questionnaire is not completed and we don't have any completion date
        if (this.iacCompletionDate && this.iacCompletionDate.sec) {
            iacCompletionDate = null;
        } else {
            iacCompletionDate = this.iacCompletionDate;
        }
        return this.convertDate(iacCompletionDate, this.translateService.currentLang);
    }

    /**
     * Alias - iac is both a test AND a report.
     * To take account four both, we define this
     * alias
     */

    get iacDate() {
        return this.iacCompletionDate;
    }

    /**
     * Alias - iac is both a test AND a report.
     * To take account four both, we define this
     * alias
     */

    get iacDateToDisplay() {
        let iacDate;
        // check if the CAI date is an object that contains a sec property
        if (this.iacCompletionDate && this.iacCompletionDate.sec) {
            iacDate = this.iacCompletionDate.sec * 1000;
        } else {
            iacDate = this.iacCompletionDate;
        }
        return this.convertDate(iacDate, this.translateService.currentLang);
    }

    /**
     * Duplicated method? Replaced by firstDate() -- renamed as mpoCompletionDate
     */
    // get completionDate() {}

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Compounded getters

    /**
     * whether or not to display send an MPO questionnaire
     * - name changed from displaySendMpo() :: getters are
     * person centric, not component / display oriented.
     */
    get canTakeMpo() {
        return !(this.hasAnMpoReport || this.hasMpoRequest);
    }

    get hasMpo() {
        return !this.canTakeMpo;
    }

    /**
     * whether or not to display the resend / delete options for an mpo questionnaire
     * - name changed from displayResendAndDeleteMpo()
     */

    get hasAnMpoQuestionnaireSent(): boolean {
        return (
            !this.hasAnMpoReport && this.hasMpoRequest
        );
    }

    get hasAnIacQuestionnaireSent(): boolean {
        return (
            !this.hasAnIacReport && this.hasIacRequest
        );
    }

    /**
     * Return whether this person has no mpo.
     * - name changed from displaySendMpo()
     */
    get hasNoMpo() {
        return !(this.hasMpo || this.checkRequestsContains('isSurvey', 'mpo'));
    }

    get hasNoIac() {
        return !(this.hasIac || this.checkRequestsContains('isSurvey', 'ra'));
    }

    /**
     * whether or not to display send an pcr questionnaire
     * - name changed from displaySendPcr()
     */
    get canTakePrb() {
        return this.hasPersonality;
    }

    /**
     * ALIAS - same as hasPrbRequest
     * whether or not to display the resend / delete options for a prb questionnaire
     *
     * - name changed from displayResendAndDeletePrb
     */
    get hasAPrbQuestionnaireSent(): boolean {
        return (
            this.hasPrbRequest
        );
    }

    get isExtraPrbSent() {
        return (this.hasPersonality && this.hasPrb && this.hasPrbRequest);
    }

    get personHasAReport() {
        return !!(this.hasAnMpoReport || this.hasAnIacReport);
    }

    // Document mutators

    get jobDocument(): any {
        return _.get(this, 'job');
    }

    get jobInfo(): any {
        return _.get(this.jobDocument, 'info');
    }

    get jobIac(): any {
        return _.get(this.jobDocument, 'RA');
    }


}
