import {
    Component, ChangeDetectorRef,
    OnInit, OnDestroy
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import * as moment from 'moment';

import {ComponentPrimitive} from '../../../shared/classes/components/componentPrimitive';
import {StateService} from '../../../core/services/state/state.service';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {UserFormInitService} from '../../services/user-form-init.service';
import {User, UserData} from '../../models/user.model';
import {UserService} from '../../services/user.service';
import {UserFormDefinitionService} from '../../services/user-form-definition.service';
// import { Account, AccountData, SubAccount } from 'administration/models/account.model';
import {passwordStrengthValidator} from '../../../app/validators/password-strength';
import {emailValidator} from '../../../people/validators/email';
import {Tools} from '../../../shared/misc/tools';
import {TranslateService} from '@ngx-translate/core';
import {PermissionHelper} from '../../../shared/misc/permission.helper';
// import {IMyOptions} from 'mydatepicker';
import {DeviceDetectorService} from 'ngx-device-detector';


@Component({
    selector: 'app-administration-user-form',
    templateUrl: './administration-user-form.component.html',
    styleUrls: ['./administration-user-form.component.scss']
})
export class UserFormComponent extends ComponentPrimitive implements OnInit, OnDestroy {

    initFormData: any;
    userForm: UntypedFormGroup;
    user: User;
    userData: UserData;
    sessionStructure: any = {};
    sessionPermissions: any;
    account: any;
    errorForm: any;

    dupeUser = false;

    submitTry = false;

    focused = '';
    freezedPRB: boolean;
    public datePickerOptions: any = {dateFormat: 'dd/mm/yyyy'};

    ssOptions: any = [];

    public dropDownSettings: IDropdownSettings = {
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 1,
        enableCheckAll: false
    };

    validateFormField = Tools.validateFormField;
    sessionAccessPermission: any;
    addressInfo = {
        address: '',
        city: '',
        region: '',
        country: '',
        zipcode: ''
    };
    personalityFormationDate: any;
    communicationFormationDate: any;
    talentsFormationDate: any;

    formPermissions: any = {
        mpo: false,
        dit: false,
        talents: false,
        satellite: false,
        ra: false
    };

    usernameValidity: any = {valid: false, message: '...', init: true};

    private subscriptions = new Subscription();

    constructor(
        private route: ActivatedRoute,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        private changeDetectorRef: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        public translate: TranslateService,
        public stateService: StateService,
        public clientUser: UserService,
        private apiAdministration: ApiAdministrationService,
        private userFormInitService: UserFormInitService,
        private userFormDefinitionService: UserFormDefinitionService
    ) {
        super(stateService, translate, router, deviceService);
    }

    ngOnInit() {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = document.documentElement.scrollTop;
        // Activate routing
        this.stateService.setPropagation(this, 'admin');

        // Session structure data
        this.sessionStructure = this.stateService.session.sessionData.structure;

        // Session permissions
        this.sessionPermissions = this.stateService.session.sessionData.permissions;

        // use service to get form init information
        this.initFormData = this.userFormInitService.getFormInitDataFromRoute(this.route);

        // Form initialisation
        this.getAccountData();
        // console.log(this.initFormData);
    }

    mongoDateToDp(date, lang = 'fr') {

        if (date) {
            if (date.sec) {
                date = date.sec * 1000;
            }
            if (date.hasOwnProperty('sec') && !date.sec) {
                return;
            }
            return ('en' === lang) ?
                moment(new Date(date)).format('MM/DD/YYYY') :
                moment(new Date(date)).format('DD/MM/YYYY');
        } else {
            return;
        }
    }

    onSubAccountsChange() {
        if (this.userForm.value && this.userForm.value.subAccountAccess) {
            let accList = this.translate.instant('commons.select');
            let currentAccounts = this.checkDropDownOptions(this.userForm.value.subAccountAccess);
            this.userForm.get('subAccountAccess').setValue(currentAccounts);
            if (this.ssOptions) {
                accList = '';
                let accListSep = '';
                let accCounter = 0;
                this.ssOptions.forEach((opt) => {
                    if (opt && currentAccounts.includes(opt.id)) {
                        if (accCounter < 2) {
                            accList += accListSep + opt.name;
                        }
                        accCounter++;
                        accListSep = ', ';
                    }
                });
                if (accCounter > 2) {
                    accList += ' (+' + (accCounter - 2).toString() + ')';
                }
            }
            this.checkDropDownPlaceholder(currentAccounts, 'subAccountAccess', accList);
        }
    }

    checkFormPermission(event, report) {
        if (report) {
            this.formPermissions[report] = event.checked;
        }
    }

    initUserForm() {
        if (this.newUser) {
            this.initNewUserForm();
        } else {
            this.initEditUserForm();
        }
    }

    initNewUserForm(initPermissions = false, disablePermissions = null) {

        if (!initPermissions) {
            this.initUsersVars();
        }

        let initValues: any = false;
        if (this.account.subAccounts.length === 1) {
            initValues = {
                subAccountAccess: this.account.subAccounts[0].id,
                principalSubAccount: this.account.subAccounts[0].id
            };
        }

        if (!initPermissions) {
            this.userData.permissions = Object.assign({}, this.account.permissions);
        }

        if (initPermissions) {
            let formPermissions;
            if (this.userForm &&
                this.userForm.controls &&
                this.userForm.controls['permissions'] &&
                this.userForm.controls['permissions']['controls'] &&
                this.userForm.controls['permissions']['controls'][0]) {
                formPermissions = this.userForm.controls['permissions']['controls'][0].controls;
            }

            if (disablePermissions) {
                for (let perm of disablePermissions) {
                    if (formPermissions[perm]) {
                        formPermissions[perm].disable();
                    }
                }
            } else {
                for (let perm in this.userData.permissions) {
                    if (perm && this.userData.permissions.hasOwnProperty(perm)) {
                        formPermissions[perm].enable();
                    }
                }
            }
            if (formPermissions) {
                for (let p in formPermissions) {
                    if (p && formPermissions.hasOwnProperty(p)) {
                        if (this.userData && this.userData.permissions && this.userData.permissions.hasOwnProperty(p)) {
                            this.userData.permissions[p] = formPermissions[p].value;
                        }
                    }
                }
            }
            if (this.userForm && this.userForm.controls) {
                for (let field in this.userForm.controls) {
                    if (field && this.userForm.controls.hasOwnProperty(field)) {
                        if (field !== 'role' && field !== 'permissions' &&
                            this.userForm.get(field).value) {
                            this.userData[field] = this.userForm.get(field).value;
                        }
                    }
                }
            }
        }

        // get form definition
        let userFormDefinition = this.userFormDefinitionService.getUserNewDefinition(this.user, this.userData, initValues);

        if (this.newUser) {
            if (!initPermissions) {
                this.userForm = this.fb.group(
                    userFormDefinition,
                    {
                        validator: [
                            passwordStrengthValidator('password'),
                            emailValidator('email')
                        ]
                    }
                );
            }

        } else {
            this.userForm = this.fb.group(userFormDefinition);
        }
    }

    addressInfoVarInit(varName: string) {
        if (this.userData.addressInfo && this.userData.addressInfo[varName] !== undefined && this.userData.addressInfo[varName] !== '') {
            this.addressInfo[varName] = this.userData.addressInfo[varName];
        } else if (this.account.subAccounts && this.account.subAccounts[0][varName] !== undefined) {
            this.addressInfo[varName] = this.account.subAccounts[0][varName];
        }
    }

    initEditUserForm(initPermissions = false, disablePermissions = null) {
        this.subscriptions.add(this.apiAdministration.user([this.userId]).subscribe(
            res => {
                if (!initPermissions) {
                    this.initUsersVars(res);
                }

                // get form definition
                let userFormDefinition =
                    this.userFormDefinitionService.getUserEditDefinition(this.user, this.userData);
                this.userForm = this.fb.group(userFormDefinition);
                if (this.user && this.user.role) {
                    let formPermissions = this.userForm.controls['permissions']['controls'][0].controls;
                    if (this.user.role.includes('ROLE_USER_SATELLITE') || disablePermissions) {
                        for (let perm of ['mpo', 'dit', 'talents', 'ra']) {
                            formPermissions[perm].disable();
                        }
                    } else {
                        for (let perm in this.userData.permissions) {
                            if (perm && this.userData.permissions.hasOwnProperty(perm)) {
                                formPermissions[perm].enable();
                            }
                        }
                    }
                }

                this.addressInfoVarInit('address');
                this.addressInfoVarInit('city');
                this.addressInfoVarInit('region');
                this.addressInfoVarInit('country');
                this.addressInfoVarInit('zipcode');

                if (this.userData && this.userData.formation) {
                    if (this.userData.formation.personality) {
                        this.personalityFormationDate = this.userData.formation.personality.formationDate;
                    }
                    if (this.userData.formation.communication) {
                        this.communicationFormationDate = this.userData.formation.communication.formationDate;
                    }
                    if (this.userData.formation.talents) {
                        this.talentsFormationDate = this.userData.formation.talents.formationDate;
                    }
                }

                for (let test of ['mpo', 'dit', 'talents', 'satellite', 'ra']) {
                    this.formPermissions[test] = !!(res.permissions && res.permissions[test]);
                }

                this.changeDetectorRef.markForCheck();
            }
        ));
    }

    initUsersVars(userData = null) {
        // User
        if (this.newUser) {
            this.user = new User();
            this.userData = new UserData();
        } else {
            this.user = new User(userData);
            this.userData = new UserData(userData);

            // Status
            this.userData.status = this.userData.status ? '1' : '0';
        }
    }

    getAccountData() {
        this.subscriptions.add(this.apiAdministration.client([this.accountDataId]).subscribe(
            res => {
                this.account = res;

                // ng-multiselect activation
                for (let subAcct of this.account.subAccounts) {
                    this.ssOptions.push({id: subAcct.id, name: subAcct.name});
                }

                // Account permissions
                this.sessionAccessPermission = PermissionHelper.getAccountPermissions(this.account);

                this.initUserForm();
                this.changeDetectorRef.markForCheck();
            }
        ));
    }


    /**
     * On form sumbission
     * @param userForm form data
     */
    onSubmit(userForm) {
        this.submitTry = true;

        if (userForm.valid) {
            let formValue = userForm.value;
            formValue.formation = formValue.formation[0];
            formValue.formation.personality = formValue.formation.personality[0];
            formValue.formation.communication = formValue.formation.communication[0];
            formValue.formation.talents = formValue.formation.talents[0];
            if (formValue.formation.personality) {
                formValue.formation.personality.training = this.processBooleanField(formValue.formation.personality.training);
            }
            if (formValue.formation.communication) {
                formValue.formation.communication.training = this.processBooleanField(formValue.formation.communication.training);
            }
            if (formValue.formation.talents) {
                formValue.formation.talents.training = this.processBooleanField(formValue.formation.talents.training);
            }
            formValue.clientId = this.accountDataId;

            // New user
            if (this.newUser) {
                this.saveNewUser(formValue);
            } else {
                this.saveEditUser(formValue);
            }
        }
    }

    processBooleanField(value: boolean) {
        return (value) ? value : 0;
    }

    saveNewUser(formValue) {
        this.dupeUser = false;
        if (Array.isArray(formValue.permissions) && formValue.permissions[0]) {
            formValue.permissions = formValue.permissions[0];
        }
        this.subscriptions.add(this.apiAdministration.postUser(formValue).subscribe(
            res => {
                // console.log(res);
                this.clientUser.setUserToDisplayWithId(res.id, this.accountDataId);
                this.dupeUser = false;
                this.changeDetectorRef.detectChanges();
            },
            error => {
                if (error.error.code === 400) {
                    this.dupeUser = true;
                }
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    saveEditUser(formValue) {
        if (Array.isArray(formValue.permissions) && formValue.permissions[0]) {
            formValue.permissions = formValue.permissions[0];
        }
        this.subscriptions.add(this.apiAdministration.putUser([this.userId], formValue).subscribe(
            res => {
                // console.log(res);
                this.finish(this.accountDataId, res.id);
            },
            () => {
                // console.log(error.error);
                this.changeDetectorRef.detectChanges();
            }
        ));
    }

    get canUpdateAccess() {
        return !!(this.sessionPermissions.roles.includes('ROLE_SUPER_ADMIN')
            || this.sessionPermissions.roles.includes('ROLE_MAIN_ADMIN')
            || this.sessionPermissions.roles.includes('ROLE_ADMIN')
            || this.sessionPermissions.roles.includes('ROLE_USER_READWRITE'));
    }

    finish(accountDataId: string, userDataId = null) {
        this.router.navigate(['admin/account/' + accountDataId + '/user/' + userDataId]).then(() => {
        });
    }

    goBack() {
        // New user
        if (this.newUser) {
            this.router.navigate(['admin/account/' + this.accountDataId]).then(() => {
            });
        } else {
            this.router.navigate(['admin/account/' + this.accountDataId + '/user/' + this.userId]).then(() => {
            });
        }
    }

    onChangeFormationDate(dateData, formationObjectName: string) {
        // set formation date var for API
        if (formationObjectName) {
            if (dateData.formatted && this.userForm.controls.formation['controls']['0'].controls[formationObjectName]['controls']['0'].controls.formationDate) {
                if (this.userForm.controls.formation['controls']['0'].controls[formationObjectName]['controls']['0'].controls.formationDate.value &&
                    this.userForm.controls.formation['controls']['0'].controls[formationObjectName]['controls']['0'].controls.formationDate.value.hasOwnProperty('sec')) {
                    let dateSec = new Date(dateData.jsdate).getTime() / 1000;
                    this.userForm.controls.formation['controls']['0'].controls[formationObjectName]['controls']['0'].controls.formationDate.setValue(
                        {sec: dateSec, usec: 0}
                    );
                } else {
                    this.userForm.controls.formation['controls']['0'].controls[formationObjectName]['controls']['0'].controls.formationDate.setValue(
                        moment(new Date(dateData.jsdate)).format('YYYY-MM-DD')
                    );
                }
            } else {
                this.userForm.controls.formation['controls']['0'].controls[formationObjectName]['controls']['0'].controls.formationDate.setValue(
                    {sec: undefined, usec: undefined}
                );
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    formCheck() {
    }

    /**
     * Can select a user
     * ToDo: move it to a service or helper and add a parameter for the permissions instead of getting them using this
     * @param roleName user role name
     */
    public canSelectUser(roleName: string): boolean {
        return PermissionHelper.canSelectSatelliteUser(roleName, this.sessionAccessPermission);
    }

    checkPermissions(role) {
        let disablePermissions;
        if (role === 'ROLE_USER_SATELLITE') {
            disablePermissions = ['mpo', 'dit', 'talents', 'ra'];
            for (let r of disablePermissions) {
                this.userData.permissions[r] = false;
                this.formPermissions[r] = null;
            }
        } else {
            disablePermissions = [];
            this.userData.permissions = Object.assign({}, this.account.permissions);
            for (let p in this.userData.permissions) {
                if (p && this.userData.permissions.hasOwnProperty(p) && !this.userData.permissions[p]) {
                    disablePermissions.push(p);
                }
            }
            if (!disablePermissions.length) {
                disablePermissions = null;
            }
            if (this.userForm &&
                this.userForm.controls &&
                this.userForm.controls['permissions'] &&
                this.userForm.controls['permissions']['controls'] &&
                this.userForm.controls['permissions']['controls'][0]) {
                let formPermissions = this.userForm.controls['permissions']['controls'][0].controls;
                formPermissions['mpo'].enable();
                formPermissions['dit'].enable();
                formPermissions['talents'].enable();
                formPermissions['ra'].enable();
            }

        }

        if (this.newUser && disablePermissions) {
            this.initNewUserForm(true, disablePermissions);
        }

        this.changeDetectorRef.markForCheck();
    }

    validateUsernameEvent() {
        this.usernameValidity = {valid: false, message: '...', init: true};
        this.apiAdministration.usernameIsValid([this.userForm.get('username').value]).subscribe(
            res => {
                this.usernameValidity['valid'] = res['valid'];
                this.usernameValidity['message'] = res['valid'] ? 'Ok' : res['message'][this.translate.currentLang];
                this.usernameValidity['init'] = false;
                this.changeDetectorRef.markForCheck();
            }
        );
    }

    get newUser() {
        return ('new' === this.initFormData.status && 'user' === this.initFormData.type);
    }

    get accountDataId() {
        return this.initFormData.accountDataId;
    }

    get userId() {
        return this.initFormData.userId;
    }

    get userRoles() {
        return this.sessionStructure.userTypes.filter(
            userType => userType.value !== 'ROLE_SUPER_ADMIN'
        );
    }
}
