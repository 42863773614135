import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {DeviceDetectorService} from 'ngx-device-detector';
import {StateService} from '@core/services/state/state.service';
import {
    PeopleMultiselectActionComponent
} from '../components/web/people-multiselect-action/people-multiselect-action.component';
import {take} from 'rxjs/operators';
import {TextHelper} from '../../shared/misc/text.helper';
import {PeopleModalHandlingService} from './people-modal-handling.service';
import {ApiPeopleService} from './api-people.service';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../../shared/alert/services/alert.service';
import {PeopleList} from './people_list.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContentMultiselect} from '../../shared/classes/services/content_multiselect';
import {EnvService} from '../../app/services/env.service';

@Injectable({
    providedIn: 'root'
})
export class PeopleMultiselectService extends ContentMultiselect {

    private selectedItemsList;
    private actionToPerform: any;

    private multiselectActionModal;

    selAction;
    naWarning = false;
    noProceed = false;
    subscriptions = new Subscription();

    constructor(
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        protected stateService: StateService,
        protected peopleModal: PeopleModalHandlingService,
        protected apiPeople: ApiPeopleService,
        protected translate: TranslateService,
        protected alertService: AlertService,
        protected peopleList: PeopleList,
        private httpClient: HttpClient,
        private environment: EnvService
    ) {
        super(router, deviceService, stateService);
    }

    isActionValid(action: string): boolean {
        const validActions = ['add_jobSatisfaction', 'create_jobSatisfaction', 'create_graphboard', 'create_rightMatch', 'create_idwMatrix', 'add_idwMatrix', 'add_rightMatch', 'add_graphboard', ''];
        return validActions.includes(action);
    }

    isActionHasMpoEnabled(action: string, record: any): boolean {
        return this.isActionValid(action) && record && record.reportStatuses && record.reportStatuses.mpo;
    }

    isActionHasDitEnabled(action: string, record: any): boolean {
        const validActions = ['create_idwMatrix', 'add_idwMatrix'];
        return validActions.includes(action) && record && record.reportStatuses && record.reportStatuses.dit;
    }

    isActionEvaluationValid(action: string, record: any): boolean {
        const validIdwActions = ['create_idwMatrix', 'add_idwMatrix'];

        // Check IDW
        if (validIdwActions.includes(action)) {
            return this.isActionHasMpoEnabled(action, record) && this.isActionHasDitEnabled(action, record);
        } else {
            return this.isActionHasMpoEnabled(action, record);
        }
    }

    validityCheck(global, i = null) {
        if (!global) {
            if (this.action !== '' && this.isActionValid(this.action)) {
                return !this.isActionEvaluationValid(this.action, this.selectedItems[i]);
            }
            return false;
        } else {
            let naWarning = false;
            let counter = 0;
            if (this.selectedItems && this.action !== '') {
                for (let item of this.selectedItems) {
                    if (this.isActionEvaluationValid(this.action, item)) {
                        counter++;
                    } else if (this.isActionValid(this.action)) {
                        naWarning = true;
                        break;
                    }
                }
                if (counter !== this.selectedItems.length) {
                    this.noProceed = false;
                }
            }
            this.stateService.people.stateChanged.next({
                'refreshMultiselectPostValidityCheck': true
            });
            return naWarning;
        }
    }

    act() {
        if (this.actionToPerform) {
            if (this.isActionValid(this.actionToPerform)) {
                // Clean
                let deletedItemsIndex = [];
                for (let itemIndex in this.selectedItems) {
                    if (this.selectedItems.hasOwnProperty(itemIndex)) {
                        if (!this.isActionEvaluationValid(this.actionToPerform, this.selectedItems[itemIndex])) {
                            deletedItemsIndex.push(itemIndex);
                            // delete this.selectedItems[itemIndex];
                        }
                    }
                }

                if (this.selectedItems.length === deletedItemsIndex.length) {
                    this.noProceed = true;
                } else {
                    for (let itemIndex of deletedItemsIndex) {
                        delete this.selectedItems[itemIndex];
                    }
                    // proceed
                    let actionItems = this.actionToPerform.split('_');
                    if (actionItems[1] === 'idwmatrix') {
                        actionItems[1] = 'idwMatrix';
                    }
                    let status = 'new';
                    if (this.actionToPerform.includes('add_')) {
                        status = 'add';
                    }
                    let event = {
                        status: status,
                        actionType: actionItems[1].toLowerCase(),
                        idType: 'people',
                        ids: [],
                        multiAction: {
                            action: actionItems[0],
                            idType: 'people',
                            ids: [],
                            data: []
                        }
                    };
                    for (let item of this.selectedItems) {
                        if (item) {
                            event.ids.push(item.id);
                            event.multiAction['ids'].push(item.id);
                            event.multiAction['data'].push({id: item.id, name: `${item.firstName} ${item.lastName}`});
                        }
                    }
                    this.actOnRecords(event);
                    return 'goToNews';
                }
            } else {
                let event = {
                    actionType: this.action,
                    idType: 'people',
                    ids: []
                };
                for (let item of this.selectedItems) {
                    event.ids.push(item.id);
                }
                if (this.actionToPerform === 'subaccount' || this.actionToPerform === 'department' || this.actionToPerform === 'transferOtherAcc') {
                    this.completeAction(event);
                } else {
                    this.actOnRecords(event);
                }
            }
            return;
        }
    }

    // Modal handled here to avoid circular dependency. However, it is used just here,
    // no harm done...
    completeAction(event) {
        this.multiselectActionModal = this.peopleModal.bsModalService.show(PeopleMultiselectActionComponent, {
            initialState: {
                multiselectData: event
            },
            backdrop: 'static'
        });
        this.multiselectActionModal.content.transfer.subscribe(res => {
            if (res && res.multiselectData) {
                this.actOnRecords(res.multiselectData);
            }
        });
    }


    closeMultiselectActionModal() {
        this.multiselectActionModal.content.close.subscribe(() => {
            this.stateService.people.stateChanged.next({
                refreshList: true
            });
        });
    }


    ///

    actOnRecords(event) {
        const total = event.ids.length;
        let handled = 0;
        this.selAction = event.actionType;
        // noinspection IfStatementWithTooManyBranchesJS
        if (this.selAction === 'graphboard') {
            this.peopleModal.openAnalysisModal(event, 'graphboard');
        } else if (this.selAction === 'rightmatch') {
            if (event.status === 'new') {
                this.peopleModal.openAnalysisModal(event, 'rightMatch');
            } else {
                this.peopleModal.openRightMatchPeopleToAnalysisModal(event);
            }
        } else if (this.selAction === 'jobsatisfaction') {
            if (event.status === 'new') {
                this.peopleModal.openAnalysisModal(event, 'jobSatisfaction');
            } else {
                this.peopleModal.openWellbeingPeopleToAnalysisModal(event);
            }
        } else if (this.selAction === 'idwmatrix') {
            if (event.status === 'new') {
                this.peopleModal.openAnalysisModal(event, 'teamMatrix');
            } else {
                this.peopleModal.openIdwMatrixPeopleToAnalysisModal(event);
            }
        } else if (this.selAction === 'delete') {
            this.apiPeople.checkDelete(event.ids).pipe(take(1)).subscribe(
                res => {
                    let txtDeletionConf = {
                        paragraph: '',
                        texth1: 'Confirmation',
                        position: 'center-center',
                        template: 'warningConfirm'
                    };

                    let pAction = this.translate.instant('commons.deletionAction');
                    let actionRes = this.translate.instant('commons.multiActionRes');
                    let plural = (total > 1) ? 's' : '';
                    let paragraph: string;
                    if (Array.isArray(res) && res.length === 0) {
                        paragraph = this.translate.instant('commons.personActionConfirmation', {
                            action: pAction,
                            total: total,
                            plural: plural
                        });
                    } else {
                        paragraph = this.translate.instant('commons.multiPersonLinkedAnalysisConfirmation', {
                            action: pAction,
                            res: actionRes
                        });
                    }
                    txtDeletionConf.paragraph = paragraph;

                    this.alertService.setConfig(txtDeletionConf);
                    this.alertService.nextAction$.subscribe(
                        res2 => {
                            if (res2 === 'confirm' && this.selAction === 'delete') {
                                // Delete all selected ids
                                this.apiPeople.deleteRecords(event.ids).pipe(take(1)).subscribe(
                                    () => {
                                        this.peopleList.loading = true;
                                        const txtDeletionInner = {
                                            paragraph: (total > 1) ? this.translate.instant('commons.personsDeleted', {total: total}) : this.translate.instant('commons.personDeleted'),
                                            texth1: this.translate.instant('commons.deleted'),
                                            template: 'notice'
                                        };
                                        this.stateService.people.personToDisplayId = undefined;
                                        this.peopleList.getRecordList(true, txtDeletionInner);
                                        this.peopleList.revertToNews();
                                        this.peopleList.cleanMultiselectMode(true);
                                    },
                                    err => {
                                        this.peopleList.loading = true;
                                        console.log(err);
                                        const txtDeletionError = {
                                            paragraph: this.translate.instant('commons.tryAgain'),
                                            texth1: this.translate.instant('commons.wasError'),
                                            position: 'center-center',
                                            type: 'red'
                                        };
                                        this.peopleList.getRecordList(true, txtDeletionError);
                                    }
                                );
                                this.alertService.nextAction = new BehaviorSubject(null);
                                this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
                                this.selAction = undefined;
                            }
                        }
                    );
                }
            );
        } else {
            let putObject = {};
            let p = [];
            let h1 = [];
            let plural;
            switch (this.selAction) {
                case 'transferOtherAcc':
                    this.peopleList.switchOffFilterPanelIndicator(true);
                    if (this.peopleList._opened && this.smallSize) {
                        this.peopleList._opened = false;
                    } else {
                        this.peopleList.revertToNews();
                    }
                    this.closeMultiselectActionModal();
                    break;
                case 'subaccount':
                    this.peopleList.switchOffFilterPanelIndicator(true);
                    if (this.peopleList._opened && this.smallSize) {
                        this.peopleList._opened = false;
                    } else {
                        this.peopleList.revertToNews();
                    }
                    this.multiselectActionModal.hide();
                    putObject = {subAccount: event.container};
                    plural = (total > 1) ? 's' : '';
                    p[0] = this.translate.instant('commons.subAccountChange', {total: total, plural: plural});
                    h1[0] = this.translate.instant('commons.subAccountChangeTitle');
                    p[1] = this.translate.instant('commons.tryAgain');
                    h1[1] = this.translate.instant('commons.wasError');

                    break;
                case 'department':
                    this.peopleList.switchOffFilterPanelIndicator(true);
                    if (this.peopleList._opened && this.smallSize) {
                        this.peopleList._opened = false;
                    } else {
                        this.peopleList.revertToNews();
                    }
                    this.multiselectActionModal.hide();
                    putObject = {department: event.container};
                    plural = (total > 1) ? 's' : '';
                    p[0] = this.translate.instant('commons.depChange', {total: total, plural: plural});
                    h1[0] = this.translate.instant('commons.depChangeTitle');
                    p[1] = this.translate.instant('commons.tryAgain');
                    h1[1] = this.translate.instant('commons.wasError');

                    break;
                case 'archive':
                    plural = (total > 1) ? 's' : '';
                    p[0] = this.translate.instant('commons.multipleArchivedPerson', {total: total, plural: plural});
                    h1[0] = this.translate.instant('commons.multipleArchiving');
                    p[1] = this.translate.instant('commons.tryAgain');
                    h1[1] = this.translate.instant('commons.archivingError');
                    putObject = {archive: true};

                    break;
                case 'reactivate':
                    plural = (total > 1) ? 's' : '';
                    p[0] = this.translate.instant('commons.multipleReactivatedPerson', {total: total, plural: plural});
                    h1[0] = this.translate.instant('commons.multipleReactivation');
                    p[1] = this.translate.instant('commons.tryAgain');
                    h1[1] = this.translate.instant('commons.reactError');
                    putObject = {archive: false};

                    break;
            }
            if (this.selAction === 'archive') {
                this.apiPeople.checkDelete(event.ids).pipe(take(1)).subscribe(
                    res => {
                        let pAction = this.translate.instant('commons.archivingAction');
                        plural = (total > 1) ? 's' : '';
                        let paragraph: string;
                        if (Array.isArray(res) && res.length === 0) {
                            paragraph = this.translate.instant('commons.personActionConfirmation', {
                                action: pAction,
                                total: total,
                                plural: plural
                            });
                        } else {
                            paragraph = this.translate.instant('commons.multiPersonLinkedAnalysisConfirmation', {
                                action: pAction,
                                res: ''
                            });
                        }

                        let warnMessage = {
                            paragraph: paragraph,
                            texth1: 'Confirmation',
                            position: 'center-center',
                            template: 'warningConfirm'
                        };

                        this.alertService.setConfig(warnMessage);
                        this.alertService.nextAction$.subscribe(
                            res2 => {
                                if (res2 === 'confirm' && this.selAction === 'archive') {
                                    this.actionLoop(event, putObject, handled, total, p, h1);
                                    this.selAction = undefined;

                                    this.alertService.nextAction = undefined;
                                    this.alertService.nextAction$ = undefined;
                                    this.alertService.nextAction = new BehaviorSubject(null);
                                    this.alertService.nextAction$ = this.alertService.nextAction.asObservable();

                                }
                            }
                        );
                    });
            } else if (this.selAction === 'reactivate') {
                let pAction = this.translate.instant('commons.reactAction');
                plural = (total > 1) ? 's' : '';
                const txtArchivingConfirmation = {
                    paragraph: this.translate.instant('commons.personActionConfirmation', {
                        action: pAction,
                        total: total,
                        plural: plural
                    }),
                    texth1: 'Confirmation',
                    position: 'center-center',
                    template: 'warningConfirm'
                };
                this.alertService.setConfig(txtArchivingConfirmation);
                this.alertService.nextAction$.subscribe(
                    res => {
                        if (res === 'confirm' && this.selAction === 'reactivate') {
                            this.actionLoop(event, putObject, handled, total, p, h1);
                            this.selAction = undefined;

                            this.alertService.nextAction = undefined;
                            this.alertService.nextAction$ = undefined;
                            this.alertService.nextAction = new BehaviorSubject(null);
                            this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
                        }
                    }
                );
            } else if (this.selAction === 'transferOtherAcc') {
                let successMessage;
                let errorMessage;
                event.ids.forEach((indId) => {
                    let originAccount = (this.peopleList.sessionData && this.peopleList.sessionData.accountData) ? this.peopleList.sessionData.accountData.accountNumber : null;
                    if (indId && originAccount && event.container) {

                        let suffix = 'id_ind=' + indId + '&' + 'origin_account=' + originAccount + '&' + 'dest_account=' + event.container;
                        this.subscriptions.add(this.httpClient.get(this.environment.apiBaseUrl + (this.environment.apiBaseUrl.endsWith('/') ? '' : '/') + 'api/scrpts/transferPeople?' + suffix).subscribe(
                            (res) => {
                                this.peopleList.cleanMultiselectMode(true);

                                successMessage = TextHelper.accountTransferConfirm(this.translate.currentLang);
                                this.alertService.setConfig(successMessage);

                                this.stateService.people.stateChanged.next({
                                    'refreshList': true
                                });

                                if ((<any>res).result === true) {
                                    if (!successMessage) {
                                        successMessage = TextHelper.accountTransferConfirm(this.translate.currentLang);
                                        this.alertService.setConfig(successMessage);
                                    }
                                    errorMessage = null;
                                } else {
                                    if (!errorMessage) {
                                        errorMessage = TextHelper.errorMessage(this.translate.currentLang);
                                        this.alertService.setConfig(errorMessage);
                                    }
                                    successMessage = null;
                                }
                            },
                            () => {
                                console.log('err');
                                this.peopleList.cleanMultiselectMode(true);
                                if (!errorMessage) {
                                    errorMessage = TextHelper.errorMessage(this.translate.currentLang);
                                    this.alertService.setConfig(errorMessage);
                                }
                                successMessage = null;
                                this.stateService.people.stateChanged.next({
                                    'refreshList': true
                                });
                            })
                        );
                    }
                });
            } else if (this.selAction) {
                this.actionLoop(event, putObject, handled, total, p, h1);

                this.alertService.nextAction = undefined;
                this.alertService.nextAction$ = undefined;
                this.alertService.nextAction = new BehaviorSubject(null);
                this.alertService.nextAction$ = this.alertService.nextAction.asObservable();
            }
        }
    }

    actionLoop(event, putObject, handled, total, p, h1) {
        if (this.selAction) {
            let handledCounter = handled;
            for (let id of event.ids) {
                this.apiPeople.editPerson([id], putObject).pipe(take(1)).subscribe(
                    () => {
                        handledCounter++;
                        if (handledCounter === total) {
                            this.peopleList.loading = true;
                            this.peopleList.getRecordList(true, {
                                paragraph: p[0],
                                texth1: h1[0],
                                position: 'top-right',
                                type: 'green'
                            });

                            if (event.actionType === 'archive' || event.actionType === 'reactivate') {
                                this.peopleList.switchMode();
                                this.peopleList.cleanMultiselectMode(true);
                                this.peopleList.loading = false;
                                this.peopleList.revertToNews();
                            } else {
                                this.peopleList.cleanMultiselectMode(true);
                            }
                            this.action = null;
                        }
                    },
                    () => {
                        this.peopleList.loading = true;
                        this.peopleList.getRecordList(true, {
                            paragraph: p[1],
                            texth1: h1[1],
                            position: 'center-center',
                            type: 'red'
                        });
                    }
                );
            }
            this.selAction = undefined;
        }
    }

    removePerson(person) {
        this.removeItem(person);
        this.naWarning = this.validityCheck(true);
        this.stateService.people.multiselectWatch.next({
            'multiselectItemRemoved': true
        });
    }

    removeItem(item) {
        this.selectedItems.splice(
            this.selectedItems.indexOf(item),
            1
        );
    }

    set action(actionToPerform) {
        this.actionToPerform = actionToPerform;
    }

    get action() {
        return this.actionToPerform;
    }

    get selectedItems() {
        return this.selectedItemsList;
    }

    set selectedItems(selectedItems) {
        this.selectedItemsList = selectedItems;
    }
}
