import {ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {StateService} from '../../../core/services/state/state.service';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {ComponentPrimitive} from '../../../shared/classes/components/componentPrimitive';
import {DeviceDetectorService} from 'ngx-device-detector';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {MainListAdministrationService} from '../../services/main-list-administration.service';
import {AccountTypesHelper} from '../../commons/AccountTypesHelper';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';
import { CustomDateAdapter, CUSTOM_DATE_FORMATS } from '../../../shared/helpers/custom-date-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';

@Component({
    selector: 'app-administration-list-search-filters',
    templateUrl: './administration-list-search-filters.component.html',
    styleUrls: ['./administration-list-search-filters.component.scss'],
    providers: [
        { provide: DateAdapter, useClass: CustomDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    ],
})
export class AdministrationListSearchFiltersComponent extends ComponentPrimitive implements OnInit, OnDestroy {
    @Input() whereAmI: string;
    @Input() width = '';

    @Output() closeSearchFilters: EventEmitter<any> = new EventEmitter();
    @Output() resetSearchFilters: EventEmitter<any> = new EventEmitter();
    @Output() setCurrentAccountTypes: EventEmitter<number[]> = new EventEmitter();
    @Output() setStatus: EventEmitter<boolean> = new EventEmitter();
    @Output() setArchived: EventEmitter<boolean> = new EventEmitter();
    @Output() setClientStartDate: EventEmitter<any> = new EventEmitter();
    @Output() setClientEndDate: EventEmitter<any> = new EventEmitter();
    @Output() setDistributor: EventEmitter<string> = new EventEmitter();
    @Output() setSubsidiary: EventEmitter<string> = new EventEmitter();
    @Output() setPartner: EventEmitter<string> = new EventEmitter();
    @Output() selectPreference: EventEmitter<any> = new EventEmitter;

    private subscriptions = new Subscription();

    levels: any;

    public datePickerOptions: any = {dateFormat: 'dd/mm/yyyy'};

    public dropDownSettings: IDropdownSettings = {
        idField: 'id',
        textField: 'name',
        itemsShowLimit: 1,
        enableCheckAll: false
    };

    userPreferences: any;
    currentAccountTypes = [];
    status = null;
    dateStart = '';
    dateEnd = '';
    dateStartModel = {};
    dateEndModel = {};
    distributor: string;
    distributorSelected = '';
    subsidiaryList = [];
    distributorsList: any;
    partner: string;
    partnersList;
    partnerSelected = '';

    subsidiarySelected = '19999';

    public isFilter = false;
    public isPreferences = false;

    constructor(
        public stateService: StateService,
        public translate: TranslateService,
        private apiAdministration: ApiAdministrationService,
        private cd: ChangeDetectorRef,
        private dateAdapter: DateAdapter<Date>,
        protected router: Router,
        protected deviceService: DeviceDetectorService,
        private mainListAdministrationService: MainListAdministrationService,
    ) {
        super(stateService, translate, router, deviceService);
    }

    ngOnInit() {
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.dateAdapter.setLocale(event.lang);
        });
        // Set what section calls this component
        this.setPart(this.whereAmI);

        const userPreferences = this.stateService.admin.listSearchFilters;

        this.initLevels(userPreferences.accountTypes);

        this.getSubsidiaryList();
        this.getDistributorsList();

        if (this.translate.currentLang === 'fr') {
            this.datePickerOptions.dateFormat = 'dd/mm/yyyy';
        } else {
            this.datePickerOptions.dateFormat = 'mm/dd/yyyy';
        }

        // status
        if (typeof userPreferences.status === 'boolean') {
            this.status = userPreferences.status;
        }
        if (this.isFilter && typeof this.state.listSearchFilters.isEnabled === 'boolean') {
            this.status = this.state.listSearchFilters.isEnabled;
            this.handleSetStatus(this.status);
        }

        if (this.isFilter) {
            // in the case of a switched account
            if (this.switchAccountDataId && this.stateService.session.sessionData.accountNumber) {
                this.distributorSelected = this.stateService.session.sessionData.accountNumber;
                this.handleSetDistributor(this.distributorSelected);
            }

            // in the case of a preselected distributor
            if (this.state.listSearchFilters.distributor) {
                this.distributorSelected = this.state.listSearchFilters.distributor;
                this.handleSetDistributor(this.distributorSelected);
            }

            // in the case of a preselected partner
            if (this.state.listSearchFilters.partner) {
                this.partnerSelected = this.state.listSearchFilters.partner;
                this.handleSetPartner(this.partnerSelected);
            }

            // In case this account is subsidiary
            if (this.stateService.session.sessionData.permissions.accountType === 'subsidiary') {
                this.subsidiarySelected = this.stateService.session.sessionData.accountNumber;
                this.getDistributorsList(this.subsidiarySelected);
            }

            // In the case this account is distributor
            if (this.stateService.session.sessionData.permissions.accountType === 'distributor') {
                this.subsidiarySelected = '';
                this.distributorSelected = this.stateService.session.sessionData.accountNumber;
                this.getPartnersList(null, this.distributorSelected);
            }

            // in the case of a preselected start date
            // if (this.state.listSearchFilters.clientStartDate) {
            //     this.dateStart = this.state.listSearchFilters.clientStartDate;
            //     let dateStartArr = this.dateStart.split('-');
            //     this.dateStartModel = {
            //         date: {
            //             year: parseInt(dateStartArr[0], 10),
            //             month: parseInt(dateStartArr[1], 10),
            //             day: parseInt(dateStartArr[2], 10)
            //         }
            //     };
            // }
            if (this.state.listSearchFilters.clientStartDate) {
                this.dateStart = this.state.listSearchFilters.clientStartDate;
                let dateStartArr = this.dateStart.split('-');
                this.dateStartModel = new Date(
                    parseInt(dateStartArr[0], 10),
                    parseInt(dateStartArr[1], 10) - 1, 
                    parseInt(dateStartArr[2], 10)
                );
            }

            // in the case of a preselected end date
            if (this.state.listSearchFilters.clientEndDate) {
                this.dateEnd = this.state.listSearchFilters.clientEndDate;
                let dateEndArr = this.state.listSearchFilters.clientEndDate.split('-');
                this.dateEndModel = {
                    date: {
                        year: parseInt(dateEndArr[0], 10),
                        month: parseInt(dateEndArr[1], 10),
                        day: parseInt(dateEndArr[2], 10)
                    }
                };
            }
        }

        /**
         * Sequential load and not really a global filter. Removed for now.
         // distributor
         if (userPreferences.distributor) {
            this.distributorSelected = userPreferences.distributor;
        }
         // partner
         if (userPreferences.partner) {
            this.partnerSelected = userPreferences.partner;
        }
         */
        this.cd.markForCheck();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    setPart(part: string) {
        this.isFilter = (part === 'filter');
        this.isPreferences = (part === 'preferences');
    }

    initLevels(userPreferencesAccountTypes, reinit = false) {
        this.levels = this.mainListAdministrationService.initLevels();
        this.currentAccountTypes = (userPreferencesAccountTypes && userPreferencesAccountTypes.length !== 0) ?
            userPreferencesAccountTypes :
            AccountTypesHelper.fullArray;
        if (!reinit) {
            this.setCurrentAccountTypes.emit(this.currentAccountTypes);
        }
        this.checkAccountTypePlaceHolder();
        this.cd.markForCheck();
    }

    getSubsidiaryList() {
        this.subscriptions.add(this.apiAdministration.clients([{
            'isEnabled': 1,
            'types': ['subsidiary']
        }]).subscribe(
            res => {
                this.subsidiaryList = [
                    {
                        accountNumber: '19999',
                        name: 'NGENIO'
                    }
                ];
                for (let subsidiary of res['data']) {
                    if (subsidiary.number && subsidiary.name) {
                        this.subsidiaryList.push({
                            accountNumber: subsidiary.number,
                            name: subsidiary.name
                        });
                    }
                }
                this.cd.markForCheck();
            }
        ));
    }

    getDistributorsList(subsidiary = null) {
        this.subscriptions.add(this.apiAdministration.clients([{
            'isEnabled': 1,
            'types': ['distributor'],
            'parentAccountNumber': (subsidiary) ? subsidiary : null
        }]).subscribe(
            res => {
                this.distributorsList = [];
                for (let distributor of res['data']) {
                    if (distributor.number && distributor.name) {
                        this.distributorsList.push({
                            accountNumber: distributor.number,
                            name: distributor.name
                        });
                    }
                }
                this.cd.markForCheck();
            }
        ));
    }

    getPartnersList(country = null, distributor = null) {
        this.subscriptions.add(this.apiAdministration.clients([{
            'isEnabled': 1,
            'types': ['partner'],
            'parentAccountNumber': (distributor) ? distributor : null
        }]).subscribe(
            res => {
                this.partnersList = [];
                for (let partner of res['data']) {
                    if (partner.number && partner.name) {
                        this.partnersList.push({
                            accountNumber: partner.number,
                            name: partner.name
                        });
                    }
                }
            }
        ));
    }

    handleCloseSearchFilters() {
        this.closeSearchFilters.emit();
    }

    handleResetSearchFilters() {
        this.dateStart = null;
        this.dateStartModel = null;
        this.dateEnd = null;
        this.dateEndModel = null;
        this.subsidiarySelected = '19999';
        this.distributorSelected = '';
        this.partnerSelected = '';
        this.status = null;
        this.handleSetStatus(this.status, true);
        this.initLevels(null, true);
        this.resetSearchFilters.emit();
        this.cd.markForCheck();
    }

    checkAccountTypePlaceHolder(returnValue = false) {
        let currentAccountTypes = this.checkDropDownOptions(this.currentAccountTypes);
        let accTypesList = this.translate.instant('commons.select');
        if (this.levels[this.translate.currentLang]) {
            accTypesList = '';
            let accTypesListSep = '';
            this.levels[this.translate.currentLang].forEach((accountType) => {
                if (accountType && currentAccountTypes.includes(accountType.id)) {
                    accTypesList += accTypesListSep + accountType.name;
                    accTypesListSep = ', ';
                }
            });
        }
        this.checkDropDownPlaceholder(currentAccountTypes, 'accountTypeContainer', accTypesList);
        this.currentAccountTypes = currentAccountTypes;
        if (returnValue) {
            return currentAccountTypes;
        }
    }

    handleSetCurrentAccountTypes() {
        let currentAccountTypes = this.checkAccountTypePlaceHolder(true);
        if (this.isFilter) {
            this.setCurrentAccountTypes.emit(currentAccountTypes);
        }

        if (this.isPreferences) {
            this.selectPreference.emit({
                module: 'admin',
                key: 'accountTypes',
                value: currentAccountTypes,
                type: 'search'
            });
        }
    }

    handleSetStatus(status, reinit = false) {
        if (this.isFilter && !reinit) {
            this.setStatus.emit(status);
        }
        this.status = status;

        if (this.isPreferences) {
            this.selectPreference.emit({
                module: 'admin',
                key: 'status',
                value: status,
                type: 'search'
            });
        }
        this.cd.markForCheck();
    }

    // handleSetClientStartDate(event: MatDatepickerInputEvent<Date>) {
    //     console.log('Input event', event);
    //     let startDate = event.value;
    //     // Apply your custom date adapter to format the date
    //     let formattedDate = this.myDateAdapter.format(startDate, this.translate.currentLang);
    //     console.log('Formatted date', formattedDate);
    //     this.setClientStartDate.emit(formattedDate);
    // }
    handleSetClientStartDate(startDate: Date) {
        console.log('startDate', startDate);
        this.setClientStartDate.emit(startDate);
    }
    // handleSetClientStartDate(startDate: any) {
    //     console.log('startDate', startDate);
    //     /**
    //      * Issue with date reset: ng-sidebar Input closeOnClickOutside is set to true so the sidebar is closed if a click occurs outside it
    //      * However when a click occurs on the cross to reset a selected date, the sidebar takes that as a outside click
    //      * ng-sidebar outside click detection: https://github.com/arkon/ng-sidebar/blob/master/src/sidebar.component.ts#L537
    //      */

    //     this.setClientStartDate.emit(startDate);
    // }

    handleSetClientEndDate(endDate: any) {
        console.log('endDate', endDate);
        this.setClientEndDate.emit(endDate);
    }

    // setFilters(event: MatDatepickerInputEvent<Date>, filter) {
    //     switch (filter) {
    //         case 'clientStartDate':
    //             this.mainListAdministrationService.setSearchFilter(event.value, 'clientStartDate');
    //             break;
    //         case 'clientEndDate':
    //             this.idwMatrixSearchFiltersService.setSearchFilter(event.value, 'clientEndDate');
    //             break;
    //         }
    //     this.idwMatricesList.getIdwMatricesList(true, null, this);
    // }

    handleSetSubsidiary(subsidiary) {
        this.distributorSelected = '';
        this.partnerSelected = '';
        this.getDistributorsList(subsidiary);

        if (this.isFilter) {
            this.setSubsidiary.emit(subsidiary);
        }

        if (this.isPreferences) {
            this.selectPreference.emit({
                module: 'admin',
                key: 'subsidiary',
                value: subsidiary,
                type: 'search'
            });
        }
        this.subsidiarySelected = subsidiary;
    }

    handleSetDistributor(distributor: string) {
        this.partnerSelected = '';
        this.getPartnersList(null, distributor);


        if (this.isFilter) {
            this.setDistributor.emit(distributor);
        }

        if (this.isPreferences) {
            this.selectPreference.emit({
                module: 'admin',
                key: 'distributor',
                value: distributor,
                type: 'search'
            });
        }

        this.distributorSelected = distributor;
    }

    handleSetPartner(partner: string) {
        if (this.isFilter) {
            this.setCurrentAccountTypes.emit(null);
            this.setPartner.emit(partner);
        }

        if (this.isPreferences) {
            this.selectPreference.emit({
                module: 'admin',
                key: 'partner',
                value: partner,
                type: 'search'
            });
        }

        this.partnerSelected = partner;
    }

    get state() {
        return this.stateService.admin;
    }

    get permissionsAccountType() {
        if (this.stateService.session && this.stateService.session.sessionData && this.stateService.session.sessionData.permissions) {
            return this.stateService.session.sessionData.permissions.accountType;
        }
        return;
    }

    get isNgenio() {
        return this.stateService.session.sessionData.permissions.accountType === 'ngenio';
    }

    get isOverDistributor() {
        return !!(
            this.stateService.session &&
            this.stateService.session.sessionData &&
            this.stateService.session.sessionData.permissions &&
            this.stateService.session.sessionData.permissions.accountType &&
            (
                this.stateService.session.sessionData.permissions.accountType === 'ngenio' ||
                this.stateService.session.sessionData.permissions.accountType === 'subsidiary'
            )
        );
    }

    get isOverPartner() {
        return !!(
            this.stateService.session &&
            this.stateService.session.sessionData &&
            this.stateService.session.sessionData.permissions &&
            this.stateService.session.sessionData.permissions.accountType &&
            (
                this.stateService.session.sessionData.permissions.accountType === 'ngenio' ||
                this.stateService.session.sessionData.permissions.accountType === 'distributor' ||
                this.stateService.session.sessionData.permissions.accountType === 'subsidiary'
            )
        );
    }

    get switchAccountDataId() {
        if (this.stateService.session.sessionData.switchAccountData && this.stateService.session.sessionData.switchAccountData.accountDataId) {
            return this.stateService.session.sessionData.switchAccountData.accountDataId;
        }
        return null;
    }
}
