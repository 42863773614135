import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import * as moment from 'moment';

import {StateService} from '../../../core/services/state/state.service';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {AdminModalHandlingService} from '../../services/admin-modal-handling.service';
import {AdminService} from '../../../app/services/admin.service';
import {AccountService} from '../../services/account.service';
import {UserService} from '../../services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from '../../../shared/alert/services/alert.service';
import {take} from 'rxjs/operators';
import {LoginService} from '../../../app/services/login.service';
import {Form, UntypedFormBuilder, Validators} from '@angular/forms';

@Component({
    selector: 'app-administration-user-detail',
    templateUrl: './administration-user.component.html',
    styleUrls: ['./administration-user.component.scss']
})
export class UserDetailComponent implements OnInit, OnDestroy {

    masterSubAccount: any;
    heads: any = {};
    hierarchy: any = {};
    subAccountsAccess: Array<string>;
    usernameValidity: any = {valid: false, message: '...'};
    currentEditedField: any;
    messageFormSubmit = false;
    userInfo = [
        [
            {
                title: 'commons.firstName',
                field: 'firstName',
                editable: true,
                creation: true
            },
            {
                title: 'commons.lastName',
                field: 'lastName',
                editable: true,
                creation: true
            },
            {
                title: 'commons.creationDate',
                field: 'creationDate',
                editable: false,
                creation: false
            },
            {
                title: 'admin.userDetail.userSince',
                field: 'clientSince',
                editable: false,
                creation: false
            },
            {
                title: 'commons.email',
                field: 'email',
                editable: true,
                creation: true
            },
            {
                title: 'commons.personalEmail',
                field: 'personalEmail',
                editable: true,
                creation: true
            },
            {
                title: 'admin.commons.phoneNumber',
                field: 'phone',
                editable: true,
                creation: true
            },
            {
                title: 'admin.commons.subAccountAccess',
                field: 'subAccountAccess',
                editable: true,
                creation: true
            },
            {
                title: 'admin.commons.principalSubAccount',
                field: 'principalSubAccount',
                editable: true,
                creation: true
            }
        ],
        [
            {
                title: 'commons.title',
                field: 'jobTitle',
                editable: true,
                creation: true
            },
            {
                title: 'admin.commons.status',
                field: 'status',
                editable: true,
                creation: true
            },
            {
                title: 'commons.language',
                field: 'language',
                editable: true,
                creation: true
            }
        ]
    ];
    addressInfo = [
        [
            {
                title: 'commons.address',
                field: 'address',
                editable: true,
                creation: true
            },
            {
                title: 'commons.city',
                field: 'city',
                editable: true,
                creation: true
            },
            {
                title: 'commons.zipcode',
                field: 'zipcode',
                editable: true,
                creation: true
            }
        ],
        [
            {
                title: 'commons.region',
                field: 'region',
                editable: true,
                creation: true
            },
            {
                title: 'commons.country',
                field: 'country',
                editable: true,
                creation: true
            }
        ]
    ];
    adressInfoFields: any = ['address', 'city', 'zipcode', 'region', 'country'];
    formationFields = [
        'personality.training',
        'personality.formationDate',
        'personality.location',
        'personality.trainer',
        'communication.training',
        'communication.formationDate',
        'communication.location',
        'communication.trainer',
        'talents.training',
        'talents.formationDate',
        'talents.location',
        'talents.trainer',
    ];

    sessionStructure: any;

    personality = {
        training: false,
        formationDate: undefined,
        location: '',
        trainer: false
    };
    communication = {
        training: false,
        formationDate: undefined,
        location: '',
        trainer: false
    };
    talents = {
        training: false,
        formationDate: undefined,
        location: '',
        trainer: false
    };

    private subscriptions = new Subscription();

    sessionPermissions: any;

    displayWidth: string;
    sideWidth: string;
    principalWidth: string;
    errorMessages: any = {};
    formPermissions: any = {
        mpo: false,
        dit: false,
        talents: false,
        satellite: false,
        ra: false
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private stateService: StateService,
        public alertService: AlertService,
        public clientAccount: AccountService,
        public clientUser: UserService,
        private apiAdministration: ApiAdministrationService,
        public adminModalHandlingService: AdminModalHandlingService,
        private adminService: AdminService,
        public translate: TranslateService,
        public loginService: LoginService,
        private fb: UntypedFormBuilder
    ) {
        // Activate routing
        this.stateService.setPropagation(this, 'admin');
    }

    ngOnInit() {
        this.subscriptions.add(this.router.events.subscribe(s => {
            if (s instanceof NavigationEnd) {
                const tree = this.router.parseUrl(this.router.url);
                if (tree && tree.fragment) {

                    const element = document.querySelector('#' + tree.fragment);
                    // console.log(element);
                    if (element) {
                        element.scrollIntoView();
                    }
                }
            }
        }));

        this.stateService.admin.stateChanged$.subscribe(
            (res) => {
                if (res) {
                    this.clientUser.setClientSince();
                    this.changeDetectorRef.markForCheck();
                }
            }
        );

        this.computeListWidth(window);

        this.sessionPermissions = this.stateService.session.sessionData.permissions;

        this.sessionStructure = this.stateService.session.sessionData.structure;

        this.masterSubAccount = (this.clientAccount.subAccounts) ? this.clientAccount.subAccounts[0] : null;

        this.clientUser.setClientSince();

        this.clientUser.checkFiles();

        if (this.clientUser.formation) {
            this.initFormationVar('personality');
            this.initFormationVar('communication');
            this.initFormationVar('talents');
        }

        // Permissions
        if (this.clientUser.permissions) {
            this.formPermissions = this.clientUser.permissions;
        }

        // SubAccounts access
        if (this.clientUser.subAccountAccess) {
            this.subAccountsAccess = this.clientAccount.subAccounts
                .filter(subAccount => this.clientUser.subAccountAccess.includes(subAccount.id))
                .map(subAccount => subAccount.name)
                .join(', ');
        }

        this.hierarchy.subsidiary = (this.clientAccount.subsidiary) ? (this.clientAccount.subsidiary.name) : 'NA';
        this.hierarchy.distributor = (this.clientAccount.distributor) ? this.clientAccount.distributor.name : 'NA';
        this.hierarchy.partner = (this.clientAccount.partner) ? this.clientAccount.partner.name : 'NA';

        // translation of headings of subComponents
        let heads = {
            fr: {
                createItemLabel: '+ Ajouter un fichier',
                listTitle: 'Fichiers',
                listTitles: ['Type', 'Nom du fichier', 'Ajouté par', 'Date', 'Taille']
            },
            en: {
                createItemLabel: '+ Add a file',
                listTitle: 'Files',
                listTitles: ['Type', 'Name of file', 'Added by', 'Date', 'Size']
            }
        };
        this.heads = heads[this.translate.currentLang];
        this.subscriptions.add(this.translate.onLangChange.subscribe(
            (e) => {
                this.heads = heads[e.lang];
            }
        ));
    }

    closeThis(event) {
        this.currentEditedField = event;
    }

    computeListWidth(startWidth) {
        let adjustment: any = {left: -5, right: -8};
        let widthRatio: number = 3 / 4;
        let leftColumnWidth = 100;
        let innerWidth = startWidth.innerWidth;
        if (startWidth.innerWidth < 1280) {
            innerWidth = 1280;
        }
        this.displayWidth = (innerWidth - leftColumnWidth) * widthRatio + adjustment.left + 'px';
        if (!this.clientUser.smallSize) {
            this.sideWidth = '250px';
            this.principalWidth = (window.innerWidth - 367) + 'px';
        } else {
            let displayWidthNumber = (innerWidth - leftColumnWidth) * widthRatio + adjustment.left;
            this.sideWidth = (displayWidthNumber - 35) + 'px';
            this.principalWidth = (window.innerWidth - 117) + 'px';
        }

        return;
    }

    onResize(event) {
        // re-Set list width
        this.computeListWidth(event.target);
    }

    initFormationVar(propName: string) {
        if (this.clientUser.formation[propName]) {
            this[propName].training = !!(this.clientUser.formation[propName].training);
            if (this.clientUser.formation[propName].formationDate) {
                this[propName].formationDate = this.mongoDateToDp(this.clientUser.formation[propName].formationDate, this.translate.currentLang);
            }
            this[propName].location = this.clientUser.formation[propName].location;
            this[propName].trainer = this.clientUser.formation[propName].trainer;
        }
    }

    fetchSubAccountName(id) {
        for (let account of this.clientAccount.subAccounts) {
            if (account.id === id) {
                return account.name;
            }
        }
    }

    getProperty(ctx, property) {
        if (ctx[property] !== null && ctx[property] !== undefined && ctx[property] !== '') {
            return ctx[property];
        }
        return null;
    }

    processBooleanField(value: boolean) {
        return (value) ? value : 0;
    }

    editRefresh() {
        this.clientUser.setUserToDisplayWithId(this.clientUser.id);
    }

    /**
     * Select file
     */
    onSelectFile(file) {
        this.subscriptions.add(this.adminService.getImage({type: 'userAssets', filename: file.filename}).subscribe(
            data => {
                const urlData = 'data:' + file.mimeType + ';base64,' + data.image;
                this.downloadURI(urlData, file.originalName);
            }
        ));
    }

    /**
     * Download file event
     */
    downloadURI(uri, name) {
        let link = document.createElement('a');
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    mongoDateToDp(date, lang = 'fr') {
        if (date) {
            if (date.sec) {
                date = date.sec * 1000;
            }
            if (date.hasOwnProperty('sec') && !date.sec) {
                return;
            }
            return ('en' === lang) ?
                moment(new Date(date)).format('MM/DD/YYYY') :
                moment(new Date(date)).format('DD/MM/YYYY');
        } else {
            return;
        }
    }

    deleteUser() {
        const txtDeletion = (this.translate.currentLang === 'fr') ? {
            paragraph: 'Attention. Vous êtes sur le point de supprimer cet utilisateur. Désirez-vous continuer ?',
            texth1: 'Confirmation',
            position: 'center-center',
            template: 'warningConfirm'
        } : {
            paragraph: 'Warning. You are about to delete this user. Do you want to continue?',
            texth1: 'Confirmation',
            position: 'center-center',
            template: 'warningConfirm'
        };

        this.alertService.setConfig(txtDeletion);
        let deleteAccountSubscription = this.alertService.nextAction.subscribe(
            res => {
                if (res === 'confirm') {
                    this.apiAdministration.deleteUser([this.clientUser.id]).subscribe(
                        () => {
                            this.router.navigate(['admin/account/' + this.clientAccount.id]).then(() => {});
                            deleteAccountSubscription.unsubscribe();
                        }
                    );
                }
            }
        );
    }

    /**
     * Delete file
     */
    onDeleteFile(file) {

        let deleteMessage = this.translate.instant('admin.accountDetail.fileDeletionConfirm', {
            fileName: file.name,
        });

        // Open alert
        this.alertService.setConfig({
            paragraph: deleteMessage,
            texth1: 'Confirmation',
            position: 'center-center',
            template: 'warningConfirm'
        });

        // Alert actions
        this.subscriptions.add(this.alertService.nextAction.subscribe(
            (res: string) => {
                if (res && res.toLowerCase() === 'confirm') {
                    let fileFound = false;
                    this.clientUser.assets.forEach(asset => {
                        if (asset.filename === file.filename) {
                            fileFound = true;
                        }
                    });
                    // Delete file
                    if (fileFound) {
                        this.subscriptions.add(this.apiAdministration.deleteUserFile(
                            [this.clientUser.id, file.filename]
                        ).subscribe(
                            (deletionRes) => {
                                if (deletionRes.success) {
                                    // Reload user
                                    this.clientUser.setUserToDisplayWithId(this.clientUser.id);
                                }
                            }
                        ));
                    }
                }
            }
        ));
    }

    onChangeFormationDate(dateData, formationObjectName: string) {
        // set formation date var for API
        if (formationObjectName) {
            if (dateData.formatted && this.clientUser.formation[formationObjectName].formationDate) {
                if (this.clientUser.formation[formationObjectName].formationDate &&
                    this.clientUser.formation[formationObjectName].formationDate.hasOwnProperty('sec')) {
                    let dateSec = new Date(dateData.jsdate).getTime() / 1000;
                    this.clientUser.formation[formationObjectName].formationDate = {sec: dateSec, usec: 0};
                } else {
                    this.clientUser.formation[formationObjectName].formationDate = moment(new Date(dateData.jsdate)).format('YYYY-MM-DD');
                }
            } else {
                this.clientUser.formation[formationObjectName].formationDate = {sec: undefined, usec: undefined};
            }

            if (this.clientUser.formation && this.clientUser.formation.personality) {
                this.personality.formationDate = this.mongoDateToDp(this.clientUser.formation.personality.formationDate, this.translate.currentLang);
            }
            if (this.clientUser.formation && this.clientUser.formation.communication) {
                this.communication.formationDate = this.mongoDateToDp(this.clientUser.formation.communication.formationDate, this.translate.currentLang);
            }
            if (this.clientUser.formation && this.clientUser.formation.talents) {
                this.talents.formationDate = this.mongoDateToDp(this.clientUser.formation.talents.formationDate, this.translate.currentLang);
            }
        }
        this.changeDetectorRef.markForCheck();
    }

    validateUsernameEvent() {
        this.usernameValidity = {valid: false, message: '...'};
        this.apiAdministration.usernameIsValid([this.clientUser.userToDisplay['username']]).subscribe(
            res => {
                this.usernameValidity['valid'] = res['valid'];
                this.usernameValidity['message'] = res['valid'] ? 'Ok' : res['message'][this.translate.currentLang];
                this.changeDetectorRef.markForCheck();
            }
        );
    }

    reinitPassword() {
        let forgetPassForm = {
                username: this.clientUser.username,
                lang: this.translate.currentLang
            }
        this.loginService.resetPassword(forgetPassForm).pipe(take(1)).subscribe(
            () => {
                this.messageFormSubmit = true;

                this.changeDetectorRef.markForCheck();
            }
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    get canDeleteUsers() {
        return this.sessionPermissions.roles.includes('ROLE_SUPER_ADMIN');
    }

    get canEditUsers() {
        return (this.sessionPermissions.roles.includes('ROLE_SUPER_ADMIN') ||
            this.clientUser.username === this.stateService.session.sessionData.userData.username);
    }

    get canAdminEditUsers() {
        return !!(
            this.sessionPermissions.roles.includes('ROLE_SUPER_ADMIN') ||
            this.sessionPermissions.roles.includes('ROLE_MAIN_ADMIN') ||
            this.sessionPermissions.roles.includes('ROLE_ADMIN')
        );
    }

    get state(): any {
        return this.stateService.admin;
    }

    get userRoles() {
        return this.sessionStructure.userTypes.filter(
            userType => userType.value !== 'ROLE_SUPER_ADMIN'
        );
    }
}
