import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import {StateService} from '../../../core/services/state/state.service';
import {ApiAdministrationService} from '../../services/api-administration.service';
import {ChangeDetectorRef} from '@angular/core';

@Component({
    selector: 'app-administration-purchase-product-modal',
    templateUrl: './administration-purchase-product-modal.component.html',
    styleUrls: ['./administration-purchase-product-modal.component.scss']
})
export class PurchaseProductModalComponent implements OnInit {
    @Input() account: any = null;

    public close: EventEmitter<any> = new EventEmitter();
    public editRefresh: EventEmitter<any> = new EventEmitter();

    country = null;
    productGroup = null;
    product = null;
    extraDescription = '';
    products = [];

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        public bsModalRef: BsModalRef,
        private stateService: StateService,
        private apiAdministration: ApiAdministrationService,
        public translate: TranslateService
    ) {}

    ngOnInit() {
        this.apiAdministration.productsClient([this.account.id]).subscribe(
            (res) => {
                this.products = res;
                console.log('products', this.products);
            }
        );
    }

    closeModal() {
        this.bsModalRef.hide();
        this.country = null;
        this.productGroup = null;
        this.product = null;
        this.extraDescription = '';
        this.close.emit({closed: true});
    }

    buyProduct() {
        this.apiAdministration.postPurchaseProduct({
            'productId': this.products.find(product => product.sku === this.product)['id'],
            'subAccount': 0,
            'extraDescription': this.extraDescription,
            'clientId': this.account.id,
            'sku': this.product
        }).subscribe(
            () => {
                this.stateService.admin.stateChanged.next({refreshComponent: true});
                this.closeModal();
            },
            error => {
                throw 'There was an error purchasing the product' + error.error;
            }
        );
    }

    refresh() {
        this.changeDetectorRef.markForCheck();
    }

    get accountType() {
        return this.account.type;
    }
}
