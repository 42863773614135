// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#wantedReportsRight {
  padding-left: 15px !important;
}

.mat-mdc-checkbox {
  padding-bottom: 3px !important;
}
.mat-mdc-checkbox label {
  margin-top: 0 !important;
}

.margin-bottom {
  margin-bottom: 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/people/components/commonClasses/people-survey-request/request-form/report-choice/report-choice.component.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;AACJ;;AAEA;EACI,8BAAA;AACJ;AAAI;EACI,wBAAA;AAER;;AAEA;EACI,kBAAA;AACJ","sourcesContent":["#wantedReportsRight {\n    padding-left: 15px !important;\n}\n\n.mat-mdc-checkbox {\n    padding-bottom: 3px!important;\n    label {\n        margin-top: 0!important;\n    }\n}\n\n.margin-bottom {\n    margin-bottom: 6px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
