export class AiAccessHelper {
    private static authorizedUsernames = [
        'mbelanger@lesalchimistesconseils.com', // 10005
        'rchausse@lesalchimistesconseils.com', // 10005
        'nancycnormand@lesalchimistesconseils.com', // 10005
        'CREACORDH', // 10047
        'NicolePare', // 10047
        'NicolasB', // 31345
        'ngadmin', // SuperAdmin 19999
    ];

    public static isUserAuthorized(username: string): boolean {
        return AiAccessHelper.authorizedUsernames.includes(username);
    }
}
