import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ApiPeopleService } from '../../../services/api-people.service';
import { PersonService } from '../../../services/person.service';
import { MarkdownToHtmlHelper } from '../../../../shared/helpers/markdown-to-html.helper';
import { StateService } from '../../../../core/services/state/state.service';
import { timeoutWith, catchError, delay } from 'rxjs/operators';
import { throwError, Subject, of } from 'rxjs';
import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserService } from '../../../../core/services/user/user.service';

@Component({
    selector: 'app-people-assistant-modal',
    templateUrl: './people-assistant-modal.component.html',
    styleUrls: ['./people-assistant-modal.component.scss'],
})
export class PeopleAssistantModalComponent implements OnInit, OnDestroy {
    @ViewChild('checkbox') checkbox: ElementRef;
    
    public close: EventEmitter<any> = new EventEmitter();
    private onDestroy = new Subject<void>();

    otherIconsFolder = 'assets/other_icons/';

    feedbackMode = false;
    feedback = '';
    feedbackSubmitted = false;
    questionAsked = false;

    userInput = '';
    userQuestion: string;
    messages = { response: '' };
    response = '';

    isExperimental = false;
    isShortAnswer = false;

    loadingState = 0;
    additionalLoading: boolean = false;
    loadingIntervalId: any;
    timeoutId: any;

    iconClicked = false;
    // *************************************************
    // https://ngenio.atlassian.net/browse/MPO-3792    *   
    // Pour la démo en France nous desactivons le      *
    // requis d'acceptation des termes et conditions   *
    // *************************************************
    firstTimeUser = false;
    isErrorMessage = false;
    selectedQuestion: string;
    openSidebar = false;
    aiContext = 'traits';
    copySuccess = false;
    currentSelection: string;
    actualContext = '';
    placeholderText: string;

    showPcrMessage: boolean = false;

    termsOfServiceHtml: string;

 

    //
    // Uncomment if/when questions are re enabled
    //
    // @Output()
    // openCategoryEvent = new EventEmitter<string>();



    buttonStateAndIndex = {
        personaliteSelected: { selected: false, categoryIndex: 0 },
        pcrSelected: { selected: false, categoryIndex: 1 },
        talentsSelected: { selected: false, categoryIndex: 2 },
        iacSelected: { selected: false, categoryIndex: 3 }
    };

    constructor(
        public bsModalRef: BsModalRef,
        public cd: ChangeDetectorRef,
        private stateService: StateService,
        protected personService: PersonService,
        protected translateService: TranslateService,
        protected userService: UserService,
        private apiPeople: ApiPeopleService,
     ) {}
     
     ngOnInit() {
        const reportType = this.stateService.people.reportType;
        switch (reportType) {
            case 'personality':
            case 'communication':
                this.toggleButton('personaliteSelected');
                this.currentSelection = 'personaliteSelected';
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_traits';
                this.aiContext = 'traits';
                break;
            case 'talents':
                this.toggleButton('talentsSelected');
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_talents';
                this.aiContext = 'talents';
                break;
            case 'iac':
                this.toggleButton('iacSelected');
                this.currentSelection = 'iacSelected';
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_iac';
                this.aiContext = 'iac';
                break;
            default:
                this.toggleButton('personaliteSelected');
                this.currentSelection = 'personaliteSelected';
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder';
                this.aiContext = 'traits';
                break;
        }
        
        //
        // Uncomment if/when questions are re enabled
        //
        // this.openCategoryEvent.emit('personaliteSelected');
        // this.startLoadingAnimation();
        
    }

     startLoadingAnimation() {
        const startTime = Date.now();
        this.loadingIntervalId = setInterval(() => {
            this.loadingState = (this.loadingState + 1) % 4;
            // console.log(`Time: ${Date.now() - startTime}ms, Loading State: ${this.loadingState}`);
        }, 500);

        setTimeout(() => {
            this.additionalLoading = true;
            // console.log(`Time: ${Date.now() - startTime}ms, Additional Loading: ${this.additionalLoading}`);
        }, 9000);
    }
    
    askQuestion() {
        this.isErrorMessage = false;
        this.messages = { response: '' };
        this.additionalLoading = false;
        this.startLoadingAnimation();
        this.userInput = this.selectedQuestion;
        this.userQuestion = this.userInput;
        this.questionAsked = true;
        const currentLang = this.translateService.currentLang;
        let data = {
            question: this.userInput,
            currentLang: currentLang,
            experimental: this.isExperimental ? 'true' : 'false',
        };
        data['personalityScores'] = {};
        for (let trait of ['OR', 'SE', 'A', 'E', 'P', 'S']) {
            if (this.personService.personalityScores[trait] !== undefined) {
                data['personalityScores'][trait] =
                    this.personService.personalityScores[trait].toString();
            }
        }
        if (this.aiContext === 'iac') {
            if (this.personService.iacNormalizedScore !== undefined) {
                data['iacScores'] =
                    this.personService.iacNormalizedScore.toString();
            }
        }
        if (this.aiContext === 'pcr') {
            data['prbScores'] = {};
            for (let trait of ['OR', 'SE', 'A', 'E', 'P', 'S']) {
                if (this.personService.prbScores[trait] !== undefined) {
                    data['prbScores'][trait] =
                        this.personService.prbScores[trait].toString();
                }
            }
        }
        if (this.aiContext === 'talents') {
            data['talentsScores'] = {};
            data['talentsScores'] = this.personService.talentsReport.info.talentsScore;
        }
        // ***
        // Retirer les commentaires pour simuler des erreurs
        // ***
        // this.apiPeople.questionAi = () => of({}).pipe(delay(31000));
        // this.apiPeople.questionAi = () => timer(2000).pipe(switchMap(() => throwError({ status: 500 })));
        this.apiPeople
            .questionAi(data, this.aiContext)
            .pipe(
                timeoutWith(30000, throwError('people.commons.request_timeout_error')),
                catchError((error) => {
                    let errorKey;
                    if (error.status === 500) {
                        errorKey = 'people.commons.server_error';
                    } else if (error === 'people.commons.request_timeout_error') {
                        errorKey = 'people.commons.request_timeout_error';
                    } else {
                        errorKey = error;
                    }

                    return this.translateService.get(errorKey).pipe(
                        switchMap((translatedErrorMessage) => {
                            clearInterval(this.loadingIntervalId);
                            clearTimeout(this.timeoutId);
                            this.isErrorMessage = true;
                            console.error('An error occurred:', error);
                            return throwError(translatedErrorMessage);
                        })
                    );
                })
            )
            .subscribe(
                (response: any) => {
                    clearInterval(this.loadingIntervalId);
                    clearTimeout(this.timeoutId);
                    console.log(response);
                    this.actualContext = response;
                    this.messages = {
                        response: MarkdownToHtmlHelper.markdownToHtml(
                            response.response
                        ),
                    };
                    this.isErrorMessage = false;
                },
                (error) => {
                    console.log('error', error);
                    this.messages = {
                        response: error,
                    };
                    this.isErrorMessage = true;
                }
            );
        this.userInput = '';
    }

    askAnotherQuestion() {
        this.actualContext['feedback'] = this.feedback;
        this.saveContext(this.actualContext);
        this.userInput = '';
        this.userQuestion = '';
        this.feedback = '';
        this.messages = { response: '' };
        this.questionAsked = false;
        this.feedbackMode = false;
        this.selectedQuestion = '';
        this.iconClicked = false;
    }

    saveContext(context) {
        this.apiPeople.saveAiContext(context).subscribe(() => { });
    }

    giveFeedback() {
        this.feedbackMode = true;
    }

    acceptTerms() {
        this.firstTimeUser = false;
    }

    submitFeedback() {
        this.askAnotherQuestion();
        this.feedbackSubmitted = true;
        setTimeout(() => {
            this.feedbackSubmitted = false;
        }, 2000);
    }

    closeModal(): void {
        this.close.emit({ closed: true });
        this.bsModalRef.hide();
    }

    thumbsIconClicked() {
        this.iconClicked = !this.iconClicked;
    }

    onQuestionSelected(question: string) {
        this.selectedQuestion = question;
    }

    copyToClipboard() {
        let responseText = this.HTMLToText(this.messages?.response);
    
        this.translateService.get(['people.commons.question', 'people.commons.response']).subscribe(translations => {
            const clipboardText = `${translations['people.commons.question']}: ${this.userQuestion}\n\n${translations['people.commons.response']}: ${responseText}`;
    
            navigator.clipboard.writeText(clipboardText).then(() => {
                this.copySuccess = true;
    
                setTimeout(() => {
                    this.copySuccess = false;
                }, 2000);
            });
        });
    }
    
    toggleButton(button: string) {
        if (this.buttonStateAndIndex[button].selected) {
            return;
        }

        for (let key in this.buttonStateAndIndex) {
            this.buttonStateAndIndex[key].selected = false;
        }

        this.buttonStateAndIndex[button].selected = true;
        this.showPcrMessage = false;
        this.aiContext = 'traits';
        this.userInput = '';
        this.selectedQuestion = '';
        if (button === 'pcrSelected') {
            this.aiContext = 'pcr';
            this.showPcrMessage = true;
        } else if (button === 'talentsSelected') {
            this.aiContext = 'talents';
            this.showPcrMessage = false;
        } else if (button === 'iacSelected') {
            this.aiContext = 'iac';
            this.showPcrMessage = false;
        }

        switch (this.aiContext) {
            case 'traits':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_traits';
                break;
            case 'pcr':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_pcr';
                break;
            case 'talents':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_talents';
                break;
            case 'iac':
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder_iac';
                break;
            default:
                this.placeholderText = 'commons.assistantVirtuel_input_placeholder';
                break;
        }
        //
        // Uncomment if/when questions are re enabled
        //
        // this.openCategoryEvent.emit(button);
        this.selectedQuestion = '';

        if (this.messages?.response) {
            this.askAnotherQuestion();
        }
    }

    toggleSidebar() {
        this.openSidebar = !this.openSidebar;
    }

    toggleCheckbox(event: Event) {
        this.checkbox.nativeElement.checked = !this.checkbox.nativeElement.checked;
        this.acceptTerms();
      }

    HTMLToText(html: string): string {
        let text = html.replace(/<\/p>/g, '\n\n');
        text = text.replace(/<[^>]*>/g, '');
        text = text.replace(/\n\s*\n/g, '\n\n');
        return text;
    }

    isPcrValid(): number {
        let lastReport = this.personService.lastPrbReport;
        if (lastReport && lastReport.info && lastReport.info.date) {
            let today = new Date();
            let prbDate = new Date(lastReport.info.date);
            let diffDate = Math.floor((today.getTime() - prbDate.getTime()) / 86400000);
            if (diffDate >= 120) {
                return 2;
            } else if (diffDate >= 60) {
                return 1;
            } else {
                return 0;
            }
        }
        return 0;
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    get isInputInvalid(): boolean {
        if (this.aiContext === 'pcr' && this.isPcrValid() === 2) {
            return true;
        }
        return (!this.userInput || this.userInput.length < 1) && (!this.selectedQuestion || this.selectedQuestion.length < 1);
    }
}